import React, { useEffect, useMemo,  useState } from "react";
import pic1 from "../assets/1.webp";
import pic2 from "../assets/2.webp";
import pic3 from "../assets/3.webp";
import pic4 from "../assets/4.webp";

import carousel_1 from "../assets/carousel_1.webp";
import carousel_2 from "../assets/carousel_2.webp";
import carousel_3 from "../assets/carousel_3.webp";
import carousel_4 from "../assets/carousel_4.webp";
import carousel_5 from "../assets/carousel_5.webp";
import carousel_6 from "../assets/carousel_6.webp";
import carousel_7 from "../assets/carousel_7.webp";
import carousel_8 from "../assets/carousel_8.webp";
import about_main1 from "../assets/about_main1.webp";
import about_main2 from "../assets/about_main2.webp";
import about_main3 from "../assets/about_main3.webp";
import WebFont from "webfontloader";


import maaPic from "../assets/maap.webp";

import { CircleLoadingAnimation } from "../components/circleAnimationSvg";
import { yellowprimary } from "../components/appComponent";
import { useAuth } from "../appContexts/authContext";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";
import Header from "../components/headerBar";
import { BookingRequests } from "../requests.js/bookingRequests";
import { DonationgRequests } from "../requests.js/donationRequests";
import { ActivitesRequests } from "../requests.js/activitiesRequests";
import { HomePageRequests } from "../requests.js/homePageRequests";
import { UserRequests } from "../requests.js/userRequests";

const preloadSrcList = [
  carousel_1,
  carousel_2,
  carousel_3,
  carousel_4,
  carousel_5,
  carousel_6,
  carousel_7,
  carousel_8,
  maaPic,
];

function preloadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}

export default function LoadingPage({ showHeader = true }) {
  const [assetsLoaded, setAssetsLoaded] = useState(false);
  const { checkAuth, authUiState } = useAuth();
  let { bookingId, donationId } = useParams();
  const [outletProps, setoutletProps] = useState({});
  const navigate = useNavigate();
  var location = useLocation();
  useMemo(async () => {
    console.log(location);
    let isCancelled = false;
    var isAnonymous = false;
    if (authUiState === "signedOut") {
      isAnonymous = await checkAuth();
    }
    setAssetsLoaded(false);
    if (isCancelled) {
      return;
    }
    var promiseList = [];
    /// common assets
      WebFont.load({
        custom: {
          families: ["Regular:n7,n4"],
          urls: ["../index.css"],
        },
      });
    const bookingIdpattern = /^\/bookings\/\d+$/;
    const donatonIdpattern = /^\/donations\/\d+$/;
    if (location.pathname === "/") {
      /// home page assets
      for (const i of preloadSrcList) {
        promiseList.push(preloadImage(i));
      }
      async function loadHomeData() {
        // await AuthRequests.validateUser();
        var homePageData = await HomePageRequests.getHomePageData();

        setoutletProps(homePageData);
      }
      promiseList.push(loadHomeData());
      await Promise.all(promiseList);
      promiseList=[];
    }else if (location.pathname === "/about"){
      for (const i of [about_main1,about_main2,about_main3,pic1,pic2,pic3,pic4]) {
        promiseList.push(preloadImage(i));
      }
    } else if (location.pathname === "/profile") {
      if (isAnonymous) {
        navigate("/");
        return;
      }
      const { bookings, donations, user } =
        await UserRequests.getProfileData();
      setoutletProps({
        bookings: bookings,
        donations: donations,
        user: user,
      });
      for (const i of bookings) {
        promiseList.push(preloadImage(i.booking_type.url));
      }
    } else if (location.pathname === "/profile/editProfile") {
      const user  =
        await UserRequests.getUser();
      setoutletProps({
        user: user,
      });
    } else if (
      location.pathname === "/bookings" ||
      location.pathname === "/bookings/"
    ) {
      var bookings = await BookingRequests.getBookingsTypes();
      setoutletProps({ bookings: bookings });
      for (const i of bookings) {
        promiseList.push(preloadImage(i.url));
      }
    } else if (bookingIdpattern.test(location.pathname)) {
      async function loadBookingData() {
        var booking = await BookingRequests.getBookingById(bookingId);
        setoutletProps({ booking: booking });
        await preloadImage(booking.booking_type.url);
      }
      promiseList.push(loadBookingData());
    } else if (
      location.pathname === "/donations" ||
      location.pathname === "/donations/"
    ) {
        var donations = await DonationgRequests.getDonationTypes();
        setoutletProps({ donations: donations });
     

    } else if (donatonIdpattern.test(location.pathname)) {
      async function loadDonationData() {
        var donation = await DonationgRequests.getDonationById(donationId);
        setoutletProps({ donation: donation });
      }

      promiseList.push(loadDonationData());
    } else if (location.pathname === "/activity") {
        var activites = await ActivitesRequests.getActivites();
        setoutletProps({ activites: activites });
      for (const i of activites) {
        promiseList.push(preloadImage(i.url));
      }
    }
    
    await Promise.all(promiseList);

    if (isCancelled) {
      return;
    }
    setAssetsLoaded(true);
    return () => {
      isCancelled = true;
    };
  }, [location]);

  useEffect(() => {
  }, [authUiState]);

  // useEffect(() => {
  //   console.log(location);
  //   let isCancelled = false;

  //   async function loadData() {

  //     setAssetsLoaded(false);
  //     if (isCancelled) {
  //       return;
  //     }
  //     const promiseList = [];

  //     /// common assets
  //     promiseList.push(
  //       WebFont.load({
  //         custom: {
  //           families: ["Regular:n7,n4"],
  //           urls: ["../index.css"],
  //         },
  //       })
  //     );
  //     const bookingIdpattern = /^\/bookings\/\d+$/;
  //     const donatonIdpattern = /^\/donations\/\d+$/;
  //     if (location.pathname === "/") {
  //       /// home page assets
  //       for (const i of preloadSrcList) {
  //         promiseList.push(preloadImage(i));
  //       }
  //       async function loadHomeData() {
  //         // await AuthRequests.validateUser();
  //         var homePageData = await HomePageRequests.getHomePageData();

  //         setoutletProps(homePageData);
  //       }
  //       promiseList.push(loadHomeData());
  //     } else if (location.pathname === "/profile") {
  //       async function loadBookingsListData() {
  //         if (await protectRoute()) {
  //           return;
  //         }
  //         var { bookings, donations, user } =
  //           await HomePageRequests.getProfileData();
  //         setoutletProps({
  //           bookings: bookings,
  //           donations: donations,
  //           user: user,
  //         });
  //       }
  //       promiseList.push(loadBookingsListData());
  //     } else if (location.pathname === "/profile/editProfile") {
  //       async function loadBookingsListData() {
  //         if (await protectRoute()) {
  //           return;
  //         }
  //         var { bookings, donations, user } =
  //           await HomePageRequests.getProfileData();
  //         setoutletProps({
  //           bookings: bookings,
  //           donations: donations,
  //           user: user,
  //         });
  //       }
  //       promiseList.push(loadBookingsListData());
  //     } else if (
  //       location.pathname === "/bookings" ||
  //       location.pathname === "/bookings/"
  //     ) {
  //       async function loadBookingsListData() {
  //         var bookings = await BookingRequests.getBookingsTypes();
  //         setoutletProps({ bookings: bookings });
  //       }
  //       promiseList.push(loadBookingsListData());
  //     } else if (bookingIdpattern.test(location.pathname)) {
  //       async function loadBookingData() {
  //         var booking = await BookingRequests.getBookingById(bookingId);
  //         setoutletProps({ booking: booking });
  //       }

  //       promiseList.push(loadBookingData());
  //     } else if (
  //       location.pathname === "/donations" ||
  //       location.pathname === "/donations/"
  //     ) {
  //       async function loadDonationListData() {
  //         var donations = await DonationgRequests.getDonationTypes();
  //         setoutletProps({ donations: donations });
  //       }
  //       promiseList.push(loadDonationListData());
  //     } else if (donatonIdpattern.test(location.pathname)) {
  //       async function loadDonationData() {
  //         var donation = await DonationgRequests.getDonationById(donationId);
  //         setoutletProps({ donation: donation });
  //       }

  //       promiseList.push(loadDonationData());
  //     } else if (location.pathname === "/activity") {
  //       async function loadActivitesListData() {
  //         var activites = await ActivitesRequests.getActivites();
  //         setoutletProps({ activites: activites });
  //       }
  //       promiseList.push(loadActivitesListData());
  //     }
  //     await Promise.all(promiseList);

  //     if (isCancelled) {
  //       return;
  //     }

  //     setAssetsLoaded(true);
  //   }

  //     loadData();

  //   return () => {
  //     isCancelled = true;
  //   };
  // }, [location]);

  if (!assetsLoaded) {
    return (
      <div
        style={{
          backgroundColor: yellowprimary,
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <CircleLoadingAnimation maxwidth={400}>
          <p style={{ margin: "0", alignSelf: "center" }}>Loading...</p>
        </CircleLoadingAnimation>
      </div>
    );
  }

  return (
    <>
      {showHeader && <Header />}
      <Outlet context={outletProps} />
    </>
  );
}
