import { AppAuth } from "../classes/appAuth";
import React, { ReactNode, useContext, useRef, useState } from "react";
import { createContext } from "react";
import { UserInterface } from "../interfaces";
import jwtDecode from "jwt-decode";
import { useGoogleLogin, googleLogout } from "@react-oauth/google";
// import { useCookies } from "react-cookie";
import Cookies from "universal-cookie";
import Snackbar from "awesome-snackbar";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, { AxiosError } from "axios";
export interface AuthContextInterface {
  user: UserInterface | null;
  appAuth: AppAuth | null;
}

const AuthUiState = Object.freeze({
  loading: "loading",
  signedIn: "signedIn",
  signedOut: "signedOut",
  otpSent: "otpSent",
});

export const AuthContext = createContext<AuthState>({
  isAnonymous: false,
  authUiState: AuthUiState.loading,
  dbUser: null,
  errorMessage: null,
  signinAnonymous: () => {},
  signinWithGoogle: () => {},
  sendOtp: (otp: string) => {},
  logout: () => {},
  checkAuth: async () => {
    return false;
  },
  requestOtp: (phoneNumber: string) => {},
  clearErrorMessage: () => {},
  requestOtpAgain: () => {},
});

// export const AppAuthComponent:React.FC<{children:ReactNode}> = ({children} ) => {

//   const contextAppAuth = useRef<AppAuth|null>(new AppAuth());

//   return (
//     <AuthContext.Provider value={{user:null ,appAuth:null}}>
//       {children}
//     </AuthContext.Provider>
//   )
// }

type AuthProps = {
  children: ReactNode;
};

type AuthState = {
  isAnonymous: boolean;
  authUiState: Object;
  dbUser: UserInterface | null;
  errorMessage: string | null;
  signinAnonymous: () => void;
  signinWithGoogle: () => void;
  sendOtp: (otp: string) => void;
  logout: () => void;
  checkAuth: () => Promise<boolean>;
  requestOtp: (phoneNumber: string) => void;
  clearErrorMessage: () => void;
  requestOtpAgain: () => void;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

interface JwtPayload {
  iss?: string;
  sub?: string;
  aud?: string[] | string;
  exp?: number;
  nbf?: number;
  iat?: number;
  jti?: string;
  "ACCESS-TOKEN"?: string;
  "REFRESH-TOKEN"?: string;
}

const AppAuthComponent: React.FC<{ children: ReactNode }> = (
  props: AuthProps
) => {
  const [dbUser, setdbUser] = useState<UserInterface | null>(null);
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const [authUiState, setAuthUiState] = useState<Object>(AuthUiState.signedOut);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  // const appAuth = useRef<AppAuth>(new AppAuth());
  const [appAuth] = useState<AppAuth>(new AppAuth());
  // const [cookies, setCookie, removeCookie] = useCookies(["Authorization"]);
  const cookies = useRef<Cookies>(new Cookies());
  const [otpCredentials, setotpCredentials] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const signinAnonymous = async () => {
    setAuthUiState(AuthUiState.loading);
    const [user]: [UserInterface, string] = await appAuth.loginAnonymous();
    setIsAnonymous(true);
    setdbUser(user);
    await checkAuth();
  };

  const googleLoginHook = useGoogleLogin({
    async onSuccess(tokenResponse) {
      setAuthUiState(AuthUiState.loading);
      console.log(tokenResponse);
      const [user]: [UserInterface, string] = await appAuth.loginWithGoogle(
        tokenResponse.access_token
      );
      localStorage.setItem("user", JSON.stringify(user));
      setdbUser(user);
      setIsAnonymous(false);
      setAuthUiState(AuthUiState.signedIn);
      checkAuth();
    },
    onError(errorResponse) {
      console.log(errorResponse);
      checkAuth();
    },
  });
  const signinWithGoogle = async () => {
    googleLoginHook();
  };
  const logout = async () => {
    setAuthUiState(AuthUiState.loading);
    setdbUser(null);
    // cookies.current.remove("Authorization");
    await appAuth.logout();
    axios.defaults.headers.common["ACCESS-TOKEN"] = null;
    googleLogout();
    await new Promise((resolve) => setTimeout(resolve, 500));
    setAuthUiState(AuthUiState.signedOut);
    /// wait for 1 second to change the authUiState state to signed out
    await new Promise((resolve) => setTimeout(resolve, 500));
    checkAuth();
  };

  const requestOtp = async (phoneNumber: string) => {
    setAuthUiState(AuthUiState.loading);
    if (phoneNumber.length !== 13) {
      setErrorMessage("Please enter a valid phone number");
      setAuthUiState(AuthUiState.signedOut);
      checkAuth();
      return;
    }
    try {
      const { credentials } = await appAuth.requestOtpcode(phoneNumber);
      setotpCredentials(credentials);
      setAuthUiState(AuthUiState.otpSent);
      setErrorMessage(null);
      setPhoneNumber(phoneNumber);
    } catch (error: any | AxiosError) {
      // check if axios error
      if (error.isAxiosError) {
        if (error.response?.data.error === "U_Controller_InvalidPhone") {
          setErrorMessage("Invalid Phone Number");
        }
        setAuthUiState(AuthUiState.signedOut);
        checkAuth();
        await new Promise((resolve) => setTimeout(resolve, 5000));
        setErrorMessage(null);
        return;
      }
    }
  };
  const sendOtp = async (otp: string) => {
    setAuthUiState(AuthUiState.loading);
    try {
      const [user]: [UserInterface, string] = await appAuth.verifyOtpCode(
        otpCredentials,
        otp
      );
      setdbUser(user);
      setIsAnonymous(false);
      setAuthUiState(AuthUiState.signedIn);
      checkAuth();
    } catch (error: any | AxiosError) {
      // check if axios error
      if (error.isAxiosError) {
        setErrorMessage(error.response?.data.message);
        setAuthUiState(AuthUiState.otpSent);
      }
    }
  };

  /// return true if user is anonymous
  const checkAuth = async () => {
    console.log("auth ui status is " + authUiState);

    if (!cookies.current.get("Authorization")) {
      await signinAnonymous();
      return true;
    } else {
      var decodedToken: JwtPayload = jwtDecode(
        cookies.current.get("Authorization")
      );
      console.log(decodedToken);
      axios.defaults.headers.common["ACCESS-TOKEN"] =
        decodedToken["ACCESS-TOKEN"];
      if (dbUser == null) {
        const user: UserInterface = await appAuth.getUser();
        if (user.auth_provider === "anonymous") {
          setIsAnonymous(true);
        } else {
          setIsAnonymous(false);
        }
        setdbUser(user);
        setAuthUiState(AuthUiState.signedIn);
        if (user.auth_provider === "anonymous") {
          return true;
        } else {
          return false;
        }
      }
      setAuthUiState(AuthUiState.signedIn);
    }
    return false;
  };
  const requestOtpAgain = async () => {
    await requestOtp(phoneNumber);
    new Snackbar(`<a class='bold'>OTP Resend Successfully!</a>`, {
      style: {
        bold: [
          ["font-weight", "bold"],
          ["font-size", "20px"],
          // ['font-family','Secondary'],
        ],
      },
    });
  };

  const clearErrorMessage = async () => {};

  return (
    <AuthContext.Provider
      value={{
        dbUser,
        isAnonymous,
        authUiState,
        errorMessage,
        signinAnonymous,
        signinWithGoogle,
        requestOtp,
        sendOtp,
        checkAuth,
        requestOtpAgain,
        logout,
        clearErrorMessage,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
export default AppAuthComponent;
