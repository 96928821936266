import React from 'react'

const OrDivider = () => {
  return (
    <div className="orDivider">
    <div className="line"></div>
    <div className="or">or</div>
    <div className="line"></div>
  </div>
  )
}

export default OrDivider