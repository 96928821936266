import axios from "axios";
import { BookingInterface, BookingTypeInterface } from "../interfaces";

export class BookingRequests {
    // static async postBooking(bookingInterface: BookingInterface): Promise<BookingInterface> {
    //     try {
    //         var response = await axios.post(
    //             `/user/booking`, bookingInterface,
    //         );
    //         return response.data;
    //     } catch (error) {
    //         console.log(error)
    //         throw error;
    //     }
    // }
    // static async getBookings(): Promise<BookingInterface[]> {
    //     try {
    //         var response = await axios.get(
    //             `/user/booking`,
    //         );
    //         return response.data;
    //     } catch (error) {
    //         console.log(error)
    //         throw error;
    //     }
    // }

    static async getBookingsTypes(): Promise<BookingTypeInterface[]> {
        try {
            var response = await axios.get(
                `/booking/types`,
            );
            return response.data;
        } catch (error) {
            console.log(error)
            throw error;
        }
    }


    static async getBookingById(id : number): Promise<BookingTypeInterface[]> {
        try {
            var response = await axios.get(
                `/booking/order/${id}?hardReload=true`,
            );
            return response.data;
        } catch (error) {
            console.log(error)
            throw error;
        }
    }
    
    static async getBookingsOrder(body:BookingInterface): Promise<BookingInterface> {
        try {
            var response = await axios.post(
                `/booking/order`,
                body

            );
            return response.data;
        } catch (error) {
            console.log(error)
            throw error;
        }
    }

}
