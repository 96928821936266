import { useEffect, useRef, useState } from "react";
import { useAuth } from "../appContexts/authContext";
import { AsYouType, MuiTelInput } from "mui-tel-input";
import { MuiOtpInput } from "mui-one-time-password-input";
import { CircleLoadingAnimation } from "./circleAnimationSvg";
import { AppButton, redprimary, yellowprimary } from "./appComponent";
import googleLogo from "../assets/google_logo.png";
import OrDivider from "./orDivider";

export const LogInComponent = ({ isLoginMenuOpen, setisLoginMenuOpen }) => {
  const menuOverlayReff = useRef();
  const [menuoverlayStyle, setmenuoverlayStyle] = useState({
    display: "block",
  });
  const [overlayBackGroungStyle, setoverlayBackGroungStyle] = useState({
    animation: "opacity-out 0.5s ease forwards ",
  });
  const [logInComponentStyle, setlogInComponentStyle] = useState({});
  const onCloseButtonPressed = async (e) => {
    e?.preventDefault();
    clearErrorMessage();
    console.log("onBackButtonPressed");
    setlogInComponentStyle({
      animation: "slide-from-left-exit 0.5s ease forwards ",
    });
    setoverlayBackGroungStyle({ animation: "opacity-in 0.5s ease forwards " });
    await new Promise((res) => setTimeout(res, 500));
    setmenuoverlayStyle({ display: "none" });
    setisLoginMenuOpen(false);
  };
  var {
    clearErrorMessage,
    authUiState,
    signinWithGoogle,
    sendOtp,
    requestOtpAgain,
    errorMessage,
    isAnonymous,
    requestOtp,
  } = useAuth();
  const [phone, setPhone] = useState("+91");

  const youType = useRef(new AsYouType());
  const handlePhoneChange = (newPhone) => {
    // console.log(newPhone);
    setPhone(newPhone);
  };
  const [otp, setOtp] = useState("");

  const handleOtpChange = (newValue) => {
    setOtp(newValue);
    console.log(newValue);
  };
  useEffect(() => {
    if (authUiState === "signedIn" && !isAnonymous) {
      onCloseButtonPressed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUiState, isAnonymous]);
  const onrequestOtpPressed = (event) => {
    event.preventDefault();
    youType.current.reset();
    youType.current.input(phone);
    // console.log(youType.current.getNumberValue());
    requestOtp(youType.current.getNumberValue());
   
  };
  const handleOtpComplete = (value) => {
    sendOtp(value);
    setOtp("");
  };

  const otpSentComponent = (
    <div className="getOtpState">
      <MuiOtpInput
        value={otp}
        onChange={handleOtpChange}
        length={6}
        onComplete={handleOtpComplete}
      />
      {errorMessage && <div className="error_message">{errorMessage}</div>}
      <div id="sendOrRequestAgain">
        <AppButton
          buttonClassName="requestAndsendOtpBtn"
          style={{
            "--btPrimary": redprimary,
            "--btSecondary": yellowprimary,
          }}
          onClick={(event) => {
            event.preventDefault();
            sendOtp(otp);
          }}
        >
          <div className="send">SEND OTP</div>
        </AppButton>
        <button
          id="RequestOtpAgain"
          onClick={(e) => {
            e.preventDefault();
            requestOtpAgain();
          }}
        >
          Request Again
        </button>
      </div>
      {/* <h1 onClick={() => auth.sendOtp('000000')}>Otp Sent</h1> */}
    </div>
  );
  const signedOutStateComponent = (
    <div className="getPhoneState">
      <MuiTelInput
        className="phoneTextField"
        value={phone}
        onChange={handlePhoneChange}
        defaultCountry="IN"
        preferredCountries={["IN"]}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onrequestOtpPressed(event);
          }
        }}
        onSubmit={onrequestOtpPressed}
      />
      {errorMessage && <div className="error_message">{errorMessage}</div>}

      <AppButton
        buttonClassName="requestAndsendOtpBtn"
        style={{
          "--btPrimary": redprimary,
          "--btSecondary": yellowprimary,
        }}
        onClick={onrequestOtpPressed}
      >
        <div className="send">REQUEST OTP</div>
      </AppButton>
      <OrDivider />
      <button
        className="googleButton"
        onClick={(e) => {
          e.preventDefault();
          signinWithGoogle();
        }}
      >
        <img className="googleLogo" src={googleLogo} alt=""></img>
        <span>Continue with Google</span>
      </button>
      {/* <button onClick={() => requestOtp('919115875195')}>Send Otp</button> */}
    </div>
  );
  return (
    <div
      className="overlay"
      ref={menuOverlayReff}
      style={{ ...menuoverlayStyle }}
    >
      <div
        className="overlayBackGround"
        style={overlayBackGroungStyle}
        onClick={onCloseButtonPressed}
      ></div>
      <div className="logInComponent" style={logInComponentStyle}>
        <AppButton
          buttonClassName="closeBtn"
          style={{
            "--btPrimary": redprimary,
            "--btSecondary": yellowprimary,
          }}
          onClick={onCloseButtonPressed}
        >
          <div className="cross">X</div>
        </AppButton>
        <div className="logInContent">
          <h1>LOGIN</h1>
          <div id="recaptcha-container" />
          {
            {
              signedOut: (
                <CircleLoadingAnimation maxwidth={'200'}>Loading...</CircleLoadingAnimation>
              ),
              loading: (
                <CircleLoadingAnimation maxwidth={'200'} >Loading...</CircleLoadingAnimation>
              ),
              otpSent: otpSentComponent,
              signedIn: isAnonymous ? signedOutStateComponent : <></>,
            }[authUiState]
          }
          {/* <div onClick={() => auth.requestOtp('919115875195')} >Send Otp request</div> */}
          {/* <div onClick={() => auth.sendOtp('000000')} >send otp</div> */}
          {/* <div>{auth.authUiState}</div> */}
          {/* <div onClick={() => auth.logout()}>SignOut</div> */}

          {/* <h1>{auth.isAuthenticated ? '   isAuthenticated:true' : '  isAuthenticated:false'}</h1>
                    <button onClick={auth.logout}>SignOut</button> */}
        </div>
      </div>
    </div>
  );
};
