import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../styles/galleryPageStyle.scss";
import { motion } from "framer-motion";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import {
  AppButton,
  CircleLoadingAnimation,
  OptimizedImage,
} from "../components/appComponent";

import { ImageRequests } from "../requests.js/imagesRequests";
import InfiniteScroll from "react-infinite-scroll-component";
import { AnimatePresence } from "framer-motion";
import saveAs from "file-saver";
import { getTokenFromFirebase } from "../firebaseClasses/appFcm";





const GalleryPage = () => {
  const galleryModels = useRef([]);
  const [currentPage, setcurrentPage] = useState(0);
  const [selectedElement, setSelectedElement] = useState(null);
  const [imageUrldata, setimageUrldata] = useState({
    noOfPages: 1,
    queryTime: "asas",
  });

  const getInitialImagesUrls = async () => {
    var response = await ImageRequests.getAllImages(0);
    galleryModels.current.push(...response.posts);
    setimageUrldata({
      noOfPages: response.noOfPages,
      queryTime: response.queryTime,
    });
  };
  const getNextImagesUrls = async () => {
    if (currentPage + 1 < imageUrldata.noOfPages) {
      var response = await ImageRequests.getAllImages(
        currentPage + 1,
        imageUrldata.queryTime
      );
      galleryModels.current.push(...response.posts);
      setcurrentPage(currentPage + 1);
    }
  };
  const [isTokenFound, setTokenFound] = useState(false);
  useEffect(() => {
    getInitialImagesUrls();
    getTokenFromFirebase(setTokenFound);
  }, []);
  const [selectedId, setSelectedId] = useState(null);
  const handleClick = async () => {
    // saveAs(
    //   selectedElement.url,

    //   `kashiAnnapurnaMathMandir-${selectedElement.ID}.jpg`
    // );
    debugger
    fetch(selectedElement.url, {
      method: 'GET',
      headers: {
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download =  `kashiAnnapurnaMathMandir-${selectedElement.ID}.jpeg`;
        link.setAttribute("download", "image.jpeg");
        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  return (
    <div className="galleryWrapper">
      <AnimatePresence>
        {selectedId && (
          <motion.div
            id="layoutWrapper"
            layoutId={selectedId}
            initial={{ backgroundColor: "transparent" }}
            animate={{ backgroundColor: "var(--color-overlay)" }}
            onClick={() => {
              setSelectedId(null);
            }}
          >
            <motion.img src={selectedElement.url} />
            <motion.div>
              <AppButton
                onClick={() => {
                  handleClick();
                }}
              >
                <div className="text">Download</div>
                <FileDownloadRoundedIcon className="downloadbtn" />
              </AppButton>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <div className="title pageTittleFont"> Gallery</div>
      <hr />
      
      {galleryModels.current.length === 0 ? (
        <CircleLoadingAnimation maxwidth={400}>
          Loading...
        </CircleLoadingAnimation>
      ) : (
        <InfiniteScroll
          style={{
            overflow: "inherit",
          }}
          className="imagesGridWrapper"
          dataLength={galleryModels.current.length}
          next={getNextImagesUrls}
          hasMore={currentPage + 1 < imageUrldata.noOfPages}
          loader={
            <CircleLoadingAnimation maxwidth={200}>
              Loading...
            </CircleLoadingAnimation>
          }
          endMessage={
            <p style={{ textAlign: "center", height:"150px" }}>
              
            </p>
          }
        >
          <div className="imagesGrid">
            {galleryModels.current?.map((s, i) => (
              <div className="item" key={s.ID}>
                <OptimizedImage
                  key={`OptimizedImage${i}`}
                  imageInterface={s}
                  setSelectedId={setSelectedId}
                  setSelectedElement={setSelectedElement}
                />
                <h2>{s.title}</h2>
              </div>
            ))}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default GalleryPage;
