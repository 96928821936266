import React, { useState } from "react";
import mainAboutPic1 from "../assets/about_main1.webp";
import mainAboutPic2 from "../assets/about_main2.webp";
import mainAboutPic3 from "../assets/about_main3.webp";
import pic1 from "../assets/1.webp";
import pic2 from "../assets/2.webp";
import pic3 from "../assets/3.webp";
import pic4 from "../assets/4.webp";
import pic5 from "../assets/activity_1.webp";
import aboutMahantImage from "../assets/about_mahant_image.png";
import { ContactComponent } from "./homePage";
import "../styles/aboutPage.scss";
import "../styles/contactComponentStyle.scss";
import {
  AppButton,
  LogInComponent,
  Menuoverlay,
} from "../components/appComponent";
import { motion } from "framer-motion";

const AboutPage = () => {
  return (
    <div className="aboutPageWrapper">
      <div className="aboutMainContent">
        <AboutFlex />
        <AboutMainImageLayers />
      </div>

      <div className="aboutContent">
        <div className="aboutTempleContent">
          <div className="photoSection">
            <img src={pic1} alt="" />
          </div>
          <div className="contentSection">
            <p>
              संसार में अन्न अर्थात खाद्य की परिपूर्ति करना चींटी से लेकर कुञ्जर
              तक, छोटे से लेकर बड़े जीव तक उसके प्राकृतिक उदर के भरण और शरीर
              पोषण के लिए आवश्यक, उपयुक्त और सुस्वादु भोज्य पदार्थों का उन तक
              पहुँचाना , उन्हें खिलाना और उन्हें खाने के योग्य रखना इन्ही देवी
              के अनुग्रह से होता है। अन्न की अधिष्ठात्री देवी केवल अन्नदाही ही
              नहीं है, अपितु वही परा शक्ति और त्रिभुवन जननी भी है।
            </p>
            <p>
              Goddess Annapurna is the presiding deity of food. In the whole
              world, providing sustenance in the form of food, from ants to
              elephants, from small to large creatures, is essential for their
              natural nourishment and bodily sustenance. The task of delivering
              appropriate, delectable edibles food to them, feeding them, and
              keeping the food suitable and palatable is the blessing of this
              goddess. The goddess of food, Mata Annapurna, is not only the
              giver of food but Mata is also the supreme power and the mother of
              the three worlds.
            </p>
            <p className="infoShloke">
              एषा त्रैलोक्य जननी साऽन्नपूर्णा महेश्वरी,
              <br />
              दुःख-दारिद्र्यशमनी सर्वसंमपत्समृद्धिदा।। <br />
              सृष्टि स्थिति - संहार करो योऽसौ महेश्वरः <br />
              स एष भगवान् रुद्रो नृत्यतेऽस्याः पुरः प्रभुः।। <br />
              योगमायां समासाद्य क्रीड़ते यो महेश्वरः
              <br />
              शिव एष ह्ययं शक्तिः मायेयं पुरुषस्त्वसौ।।
              <br />
              यत्किञ्चित् दृश्यते विप्र सर्वमेतत् द्व्यात्मकम्
              <br />
              शिवशक्त्यात्मकं विदधि जगदेतच्चराचरम्।।
            </p>
            <p>
              सृष्टि की उत्पत्ति, स्थिति और संहृति करने वाली यही आधार शक्ति है।
              इन्होने इन सबके लिए अपने को द्विधा कर लिया। पुरुष रूप में ये ही
              शिव हैं और माया रूप में ये ही शक्ति हैं। इन्ही के आदेश से सृष्टि,
              स्थिति और संहार महेश्वर शिव करते रहते हैं। इनके समक्ष वे नृत्य
              किया करते हैं। जगत में चर और अचर जो कुछ भी दिखाई देता है वह परा
              शक्ति के द्विधाभूत रुपी इसी शिव और शक्ति का प्रसार है। यह महेश्वरी
              अन्नपूर्णा जगज्जननी हैं। दुःख , दरिद्रता का विनाश करती हैं और
              संपत्ति समृद्धि को प्रदान करने वाली हैं।
            </p>
            <p>
              This is the supreme power for the creation, preservation, and
              dissolution of the universe. It has divided itself into two powers
              for these purposes. In the form of the masculine, this is Lord
              Shiva, and in the form of the feminine, this is the divine mata
              shakti. Under the directives of the Supreme Consciousness,in the
              form Brahma, Vishnu, and Maheshwar (Shiva) carry out the processes
              of creation, preservation, and dissolution. They perform their
              cosmic dance in front of them. Everything visible in the animate
              and inanimate world is the expansion of this dual-form divine
              power, which is Shiva and Shakti. This Maheshwari is the mata
              Annapurna, the mother of the universe. She destroys sorrow and
              poverty and bestows wealth and prosperity.
            </p>
          </div>
        </div>
        <div className="aboutTempleContent reverse">
          <div className="photoSection">
            <img src={pic2} alt="" />
          </div>
          <div className="contentSection">
            <p className="infoShloke">
              अहो भवानी सदने निषीदतां प्रदक्षिणीकृत्य तथा यथासुखम्।
              <br />न तत्सुखं योग-यागादि साध्यं अम्बापुरः प्राणभृतां पदाति।।
            </p>
            <p>
              जो सुख योग-याग आदि से भी प्राप्त नहीं है वही सुख भगवती अन्नपूर्णा
              के मन्दिर में जाकर बैठने वालो और उस मन्दिर की पैदल प्रदक्षिणा करने
              वाले प्राणियों को प्राप्त हो जाता है।
            </p>
            <p>
              The happiness which is not attained through practices like yoga
              and sacrifice, that is attained just by sitting in the temple of
              Goddess Annapurna and by circumambulating Annapurna temple by
              foot.
            </p>
            <p>
              काशी, विश्वेश्वरी और काशीवासियों का माहात्म्य भी इसी प्रकार कहा
              गया है -
            </p>
            <p>
              The glory of Kashi, the Divine Mata Annapurna and the residents of
              Kashi is also described in a similar manner.
            </p>

            <p className="infoShloke">
              गृहं न काशी सदृशं सुखाय पिता न विश्वेश्वरं क्वचितभवेत <br />
              माता भवानी सदृशी न गर्भहा कुटुम्ब्मत्रत्य जनो जनार्दनः।।
            </p>

            <p>
              काशी के सामान सुखद वासस्थान नहीं, विश्वेश्वर के सामान पालक पिता
              नहीं, विश्वेश्वरि अन्नपूर्णा के सदृश आवागमन से मुक्त करा देनेवाली
              कोई माता नहीं और काशिवासियो के बन्धु - बान्धव स्वयं भगवान् जनार्दन
              विष्णु ही हैं।
            </p>

            <p>
              There is no pleasant living place like kashi , There is no
              nurturing father like Vishweshwar Lord shiva and there is no
              mother like (Goddess Annapurna) Vishweshwari (Parvati) who
              liberates from the cycle of birth and death and for the residents
              of Kashi, the true friend and relative is none other than Lord
              Janardan (Vishnu) Himself.
            </p>
            <p>
              मनुष्यों की कथा ही क्या, बड़े - बड़े देवता भी काशी में निवास के
              सुख के लिए लालायित रहते हैं और भगवती पराशक्ति(annapurna) की आज्ञा
              प्राप्त करने का अवसर ताकते रहते हैं। आदेश पाते ही यहाँ आकर सानन्द
              रहते हैं -
            </p>
            <p>
              Not only human (What to say of human)? Even the mightiest of
              deities are enticed by the pleasures of dwelling in Kashi and they
              eagerly await the opportunity to receive the command of the divine
              Parashakti(mata annapurna). Upon receiving her directive, they
              reside here(kashi) in joyful abodes.
            </p>
          </div>
        </div>
        <div className="aboutTempleContent ">
          <div className="photoSection">
            <img src={pic3} alt="" />
          </div>
          <div className="contentSection">
            <p className="infoShloke">
              श्रीकालराजः प्रमथाधिपस्तथ
              <br />
              नंदीश शृङ्गीश मुनीश सूर्याः।। <br />
              आज्ञां त्वदीयां प्रसमीक्षकाः मुहुः <br />
              काश्यां स्थितिं प्राप्नुवतस्सुखाय।।
            </p>
            <p>
              कालराज कालभैरव, प्रमथाधिपति नंदी, भृंगी, मुनीश, सूर्यादि भगवती
              अन्नपूर्णा की आज्ञा की प्रतीक्षा करते रहते हैं की आदेश मिले और हम
              काशी में जाकर सानंद निवास करें जिससे भगवती अन्नपूर्णा के समीप रहने
              का सौभाग्य प्राप्त हो,और उनके दर्शनों का लाभ हो -
            </p>
            <p>
              Kalaraj Kalabhairav, the leader of Pramathas (companion of Shiva),
              Nandi, Bhrangi, Munis, Surya and others, they all await the
              command of Goddess Annapurna, longing for her directives to come
              to Kashi and reside there in bliss, in order to be fortunate
              enough to stay close to Goddess Annapurna and benefit from her
              divine presence.
            </p>
            <p className="infoShloke">
              श्रीमद्भानिसदनं समाप्य प्रदक्षिणी कृत्यतथाऽष्टावाराम्
              <br />
              स्तुतिं प्रकुर्वन्ति नरोत्तमाये प्रशिष्ठ गन्धादिभिरुत्तमास्ते।।
            </p>
            <p>
              जो भगवती जगज्जननी अन्नपूर्णा के मन्दिर में जाकर और आठ बार उसकी
              प्रदक्षिणा करके, उनकी गंधादि से पूजाकर और स्तुति करता है वह नरों
              में उत्तम नर है।
            </p>
            <p>
              Those individuals who, after going to the temple of Goddess
              Jagajjanani Annapurna, circumambulate her eight times, worship her
              with fragrant substances and offer their prayers, they are indeed
              the best among humans.
            </p>
            {/* -------- */}
            <p>स्तुति इन शब्दों में करनी चाहिए -</p>
            <p>Prayers should be offered in these words -</p>
            <p className="infoShloke">
              जय जय भवानी भवप्रिये भावाभावित भावभक्तभवे <br />
              जय जय पार्वत्यपर्णे फलप्रदान दारिद्र्य्जरे <br />
              जय जय उमे नमोनमः सेविचारणकमलनकिंजल्कप्रभे
              <br />
              जय जयअन्ननपूर्णे अन्नप्रदाननिरते अन्नार्थी संग्रहपरे <br />
              अंध - बधिर - पंगु - पतित महापापि - शरणागतत्राणनिरते
            </p>
            <p>
              हे भगवती अन्नपूर्णा आपकी जय हो, आप शंकर की प्रिया हैं,
              प्रेमश्रद्धा करने वाले भक्तों पर अनुग्रह करने वाली हैं, चारों फल
              देने वाली, दुःख-दरिद्रता को दूर करने वाली, अन्न संग्रह करके
              अभिलाषी जीवों को उन्हें अन्नदान करने वाली, अंधे - बहरे, लूले -
              लँगड़े, पतित, यहाँ तक की महापापी को भी शरण आने पर त्राण देनेवाली
              हैं, आपको बारम्बार प्रणाम करता हूँ।
            </p>
            <p>
              Oh Goddess Annapurna, jay ho. You are beloved to Lord Shankar
              (Shiva), bestowing blessings upon devotees with love and faith,
              the giver of all four types of blessings/fruits, dispeller of
              sorrow and poverty, the one who gathers and grants
              nourishment/foods to those desiring food, the refuge for the
              blind, deaf, disabled, fallen and even the gravest of sinners, I
              offer my heartfelt salutations to you again and again.
            </p>
            {/* -------- */}

            <p className="infoShloke">
              सर्वतीर्थमयी गौरी, सर्वदेवमयी शिवा। <br />
              सर्वधर्ममयी नित्या, पूजा काश्यां विशेषतः।।
            </p>
            <p>
              श्री गौरी अन्नपूर्णा में सब तीर्थ निवास करते हैं, सब देवता बसते
              हैं, सब धर्म रहते हैं, उस शिवा की पूजा सदा करनी चाहिए, विशेष रूप
              से काशी में।
            </p>
            <p>
              In Goddess Gauri Annapurna, all the pilgrimage sites reside, all
              the deities dwell, and all the virtues abide. Worship of Goddess
              Shiva(annapurna) should always be performed, especially in Kashi.
            </p>
            {/* -------- */}
            <p>
              उनके महत्त्व, पूजन यहाँ तक की प्रदक्षिणा की महत्ता इस प्रकार कही
              गई है -
            </p>
            <p>
              The significance of her worship, and the importance of even
              circumambulation, has been expressed as follows -
            </p>
            <p className="infoShloke">
              ततोऽपि कोटिगुणितमन्तरगृहे-प्रदक्षिणा ।<br />
              अंतर्गृहात बहुगुणं भवानी-क्षेत्र-प्रदक्षिणा ।।
            </p>
            <p>
              काशी में निवास करने से करोड़ गुना फलदात्री अंतर्गृही प्रदक्षिणा है
              और उससे भी बहुगुणी गौरी अन्नपूर्णा के स्थान की प्रदक्षिणा है।
            </p>
            <p>
              Antargrahi(internal-circumamblations around kashi vishwanth temple
              campus) circumambulatiion yeilds a million times greater rewards
              than Residing in Kashi and circumambulation of the abode of
              Goddess Gauri Annapurna even yields more multi-fold than
              Antargrahi(internal) circumambulatiion
            </p>
            {/* -------- */}
            <p>प्रदक्षिणा के फल की चर्चा इस प्रकार की गई है -</p>
            <p>
              The significance of circumambulation has been discussed as follows
              -
            </p>
            <p className="infoShloke">
              प्रदक्षिणीं कृतायेन महापातकनाशिनीम। <br />
              अष्टवारं सुकृतिभिः न तेषां भ्रमणं क्वचित।।
            </p>
            <p>
              जिस भी मनुष्य ने (जिन्होंने) महापातक नाश करने वाली माता अन्नपूर्णा
              की प्रदक्षिणा आठ बार भी कर ली उन्होंने अपने उस पुण्य के कारण जन्म
              - मरण के चक्कर से छुटकारा मिल जाता है।
            </p>
            <p>
              Circumambulating the Goddess Annapurna ( who eradicates great
              sins) and performing it even eight times, leads to liberation from
              the cycle of birth and death due to the accumulation of such
              meritorious(Circumambulating the Goddess Annapurna) actions.
            </p>
          </div>
        </div>
        <div className="aboutTempleContent reverse">
          <div className="photoSection">
            <img src={pic4} alt="" />
          </div>
          <div className="contentSection ">
            <p>
              अन्नपूर्णा अन्न की अधिष्ठात्री होते हुए सब प्रकार के मनोरथों को
              पूर्ण करने वाली भी हैं। उनकी पूजा व् व्रत - उत्सव के फल की तो बात
              ही क्या है, यदि कोई उनकी प्रदक्षिणा (फेरी) ही कर ले तो अनेक प्रकार
              की ऐहिक और पारलौकिक लाभ का भागी होता है, यहाँ तक की जन्म - मरण के
              भय से भी छूठ जाता है। अन्नपूर्णा वस्तुतः कल्पलता हैं, जो हर इच्छा
              को उसकी पूर्ति करती हैं।{" "}
            </p>
            <p>
              Goddess Annapurna, being the blesser of food to all all creatures,
              even fulfills all kinds of aspirations. Her worship, fasting, and
              celebrations of her festivals, not to mention just performing
              circumambulation, make one a recipient of various temporal and
              spiritual benefits. Indeed, even the fear of birth and death is
              dispelled. Annapurna is essentially the wish-fulfilling tree that
              satisfies every desire.
            </p>
            <p>
              अन्नपूर्णा मठ के दूसरे खंड में स्वर्णमयी अन्नपूर्णा की प्रतिमा है।
              जिनके दर्शन प्रतिवर्ष कृष्ण (अक्टूबर - नवंबर) और कार्तिक शुक्ल
              प्रतिपदा को केवल दिन दिनों तक ही होते हैं। फिर वर्ष भर उनके दर्शन
              नहीं होते। कार्तिक शुक्ल प्रतिपदा के दिन मंदिर के नीचे के भाग में
              अन्नकूट होता है।
            </p>
            <p>
              In the second(upper) section of the Annapurna Math (temple), there
              is a golden idol of Annapurna. The darshan (sight) of this idol is
              available only for a few days each year, specifically on the
              Krishna Dwadashi and Kartik Shukla Pratipada (just before Diwali)
              first day of the bright half of the Kartik month). Subsequently,
              the darshan remain inaccessible for the rest of the year. On
              Kartik Shukla Pratipada, an annakoot (mountain of food offerings)
              is arranged in the main temple.
            </p>
            <p>अन्नपूर्णा व्रत - Annapurna Vrat</p>
            <p>
              श्री अन्नपूर्णा व्रत का आरम्भ मार्गशीर्ष कृष्ण पंचमी से होता है और
              मार्गशीर्ष शुक्ल षष्ठी को समाप्त होता है। इस प्रकार का यह सत्रह
              दिनों का व्रत है। यदि बीच में कोई तिथि घट - बढ़ जाये तो दिनों की
              संख्या न्यूनाधिक हो सकती है पर ऊपर कही तिथियाँ व्रत के आरम्भ और
              समाप्ति की निश्चित तिथियाँ हैं। व्रत का अर्थ है किसी नियम से बंध
              कर चलना। उसका अर्थ उपवास नहीं है। श्री अन्नपूर्णा व्रत में एकाहरी
              रहना और नियमित भोजन करना स्वास्थय संमत है। शुद्ध हविष्यान्न का
              भोजन करना चाहिए। मूँग की दाल, चावल, जव का आटा, अरवी, केला,आलू ,
              कंदा इत्यादि हविष्यान्न हैं।{" "}
            </p>
            <p>
              The observance of the Shri Annapurna Vrat(fasting) begins from the
              Krishna Panchami of the month of Margashirsha(November-December)
              and concludes on Margashirsha Shukla Shashthi. Thus, this is a
              seventeen-day fast. If there is a variation in the dates in
              between, the number of days might be slightly less or more, but
              the specific dates for the beginning and completion of the fast
              are predetermined. The term "vrat" refers to abiding by certain
              rules. It does not necessarily mean fasting. It is recommended to
              eat only one meal a day at the same time everyday and only one
              type of grain maintain regular meals during the Shri Annapurna
              Vrat. One should consume pure vegetarian food, such as green gram
              lentils, rice, whole wheat flour, arbi (colocasia), banana,
              potato, sweet potato, etc.
            </p>
            <p>
              इस व्रत में प्रतिदिन कथा श्रवण करके ही भोजन करना चाहिए। व्रत के
              लिए श्री अन्नपूर्णा व्रत का डोरा हाथ में बाँधना चाहिए। पुरुष को
              दाहिने हाथ और स्त्री को बाएँ हाथ में बांधना चाहिए। कथा सुनकर अन्त
              में सात दूब और दश अक्षत तथा धूप, दीप, नैवेद्य से डोरे की पूजा करनी
              चाहिए। शुक्लपक्ष की षष्ठी तक इसी विधान से व्रत - कथा श्रवण तथा
              डोरे का पूजन करें। अगहन कृष्ण पंचमी को annapurna मंदिर में कथित
              डोरा भक्तजनों को वितरित किया जाता है। जितनी संख्यता में डोरा बनता
              है वह जब तक वितरित न हो जाये भक्तों को दिया जाता है।{" "}
            </p>
            <p>
              During this Vrat, it is advisable to listen to the narrative/story
              (katha) before taking meals. A sacred thread of the Shri Annapurna
              Vrat should be tied around the wrist upper portion of arm . Men
              should tie it on the right hand, and women on the left. After
              listening to the katha, at the end, perform worship by offering
              grass, green rice grains, incense, lamp, and sweets food to the
              thread. Following the same procedure daily, listen to the katha
              and perform the thread worship up to the Shukla Shashthi of
              Margashirsha. On the Krishna Panchami of Agahan (the month after
              Margashirsha), the tied threads are distributed to devotees in the
              annapurna temple. The number of threads prepared is distributed
              among devotees until they are exhausted.
            </p>
            <p>
              मार्गशीर्ष शुक्ल षष्ठी के दिन मध्यान्होत्तर अर्थात दोपहर 12 बजे के
              बाद अन्नपूर्णा का श्रृंगार हो जाने पर मंदिर के महन्त जब कथा में
              उल्लिखित पूजन चक्र की रचना कर पूजा कर लेते हैं तो उसके बाद भक्तजन
              करते हैं। यह पूजन तब तक चलता रहता है जब तक प्रत्येक व्रती अपना
              पूजन समाप्त न कर ले। मार्गशीर्ष शुक्ल षष्ठी के दिन श्रृंगार में
              धान की बालियाँ प्रधान वस्तु है। श्रृंगार उतर जाने पर दूसरे दिन
              अर्थात मार्गशीर्ष सप्तमी को धान की बालियाँ भक्तों को वितरित की
              जाती हैं और तब तक वितरित की जाती हैं जब तक वह समाप्त न हो जाय।
            </p>
            <p>
              On the day of Margashirsha Shukla Shashthi, after noon (around
              12:00 PM), when the decoration with rice paddy of Goddess
              Annapurna is complete, the temple's main priest arranges the
              worship according to the mentioned puja procedure. Devotees also
              participate. This worship continues until each vrat participant
              completes their worship. On Margashirsha Shukla Shashthi(in month
              of October-November), rice grains are the main offering during the
              decoration. On the following day, which is Margashirsha Saptami,
              the rice grains are distributed to the devotees. The distribution
              continues until the rice grains are exhausted.
            </p>
          </div>
        </div>
        <div className="aboutTempleContent ">
          <div className="photoSection">
            <img src={pic5} alt="" />
          </div>
          <div className="contentSection centerp">
            <p>अन्नपूर्णा स्त्रोत्रं - Annapurna Strotram</p>
            <p>
              नित्यानन्दकरी वराभयकरी सौन्दर्यरत्नाकरी
              <br />
              निर्धूताखिल-घोर-पावनकरी प्रत्यक्ष-माहेश्वरी ।
              <br />
              प्रालेयाचलवंशपावनकरी काशीपुराधीश्वरी
              <br />
              भिक्षां देहि कृपावलम्बनकरी मातान्नपूर्णेश्वरी ॥१॥
            </p>
            <p>
              नानारत्न-विचित्र-भूषणकरी हेमाम्बराडम्बरी
              <br />
              मुक्ताहार-विलम्बमान-विलसद्वक्षोज-कुम्भान्तरी ।
              <br />
              काश्मीरागरु-वासिता रुचिकरी काशीपुराधीश्वरी
              <br />
              भिक्षां देहि कृपावलम्बनकरी मातान्नपूर्णेश्वरी ॥२॥
            </p>
            <p>
              योगानन्दकरी रिपुक्षयकरी धर्मार्थनिष्ठाकरी
              <br />
              चन्द्रार्कानल-भासमान-लहरी त्रैलोक्यरक्षाकरी ।
              <br />
              सर्वैश्वर्य-समस्त-वाञ्छितकरी काशीपुराधीश्वरी
              <br />
              भिक्षां देहि कृपावलम्बनकरी मातान्नपूर्णेश्वरी ॥३॥
            </p>
            <p>
              कैलासचलं-कन्दरालयकारी गौरी उमा शङ्करी
              <br />
              कौमारी निगमार्थ-गोचरकरी ओङ्कारबीजाक्षरी ।
              <br />
              मोक्षद्वार-कपाट-पाटनकरी काशीपुराधीश्वरी
              <br />
              भिक्षां देहि कृपावलम्बनकरी मातान्नपूर्णेश्वरी ॥४॥
            </p>
            <p>
              दृश्या-दृश्य-प्रभूतवाहनकरी ब्रह्माण्डभाण्डोदरी
              <br />
              लीला-नाटक-सूत्रभेदनकरी विज्ञानदीपाङ्कुरी ।
              <br />
              श्रीविश्वेशमनःप्रसादनकरी काशीपुराधीश्वरी
              <br />
              भिक्षां देहि कृपावलम्बनकरी मातान्नपूर्णेश्वरी ॥५॥
            </p>
            <p>
              उर्वीसर्वजनेश्वरी भगवती मातान्नपूर्णेश्वरी
              <br />
              वेणीनीलसमानकुन्तलहरी नित्यान्नदानेश्वरी ।
              <br />
              सर्वानन्दकरी सदा शुभकरी काशीपुराधीश्वरी
              <br />
              भिक्षां देहि कृपावलम्बनकरी मातान्नपूर्णेश्वरी ॥६॥
            </p>
            <p>
              आदिक्षान्तसमस्तवर्णनकरी शम्भोस्त्रिभावाकरी
              <br />
              काश्मीरात्रिजलेश्वरी त्रिलहरी नित्याङ्कुरा शर्वरी ।
              <br />
              कामाकाङ्क्षकरी जनोदयकरी काशीपुराधीश्वरी
              <br />
              भिक्षां देहि कृपावलम्बनकरी मातान्नपूर्णेश्वरी ॥७॥
            </p>
            <p>
              देवी सर्वविचित्ररत्नरचिता दाक्षायणी सुन्दरी
              <br />
              वामं स्वादुपयोधरप्रियकरी सौभाग्यमाहेश्वरी ।
              <br />
              भक्ताभीष्टकरी सदा शुभकरी काशीपुराधीश्वरी
              <br />
              भिक्षां देहि कृपावलम्बनकरी मातान्नपूर्णेश्वरी ॥८॥
            </p>
            <p>
              चन्द्रार्कानलकोटिकोटिसदृशा चन्द्रांशुबिम्बाधरी
              <br />
              चन्द्रार्काग्निसमानकुन्तलधरी चन्द्रार्कवर्णेश्वरी ।
              <br />
              मालापुस्तकपाशासाङ्कुशधरी काशीपुराधीश्वरी
              <br />
              भिक्षां देहि कृपावलम्बनकरी मातान्नपूर्णेश्वरी ॥९॥
            </p>
            <p>
              क्षत्रत्राणकरी महाऽभयकरी माता कृपासागरी
              <br />
              साक्षान्मोक्षकरी सदा शिवकरी विश्वेश्वरश्रीधरी ।
              <br />
              दक्षाक्रन्दकरी निरामयकरी काशीपुराधीश्वरी
              <br />
              भिक्षां देहि कृपावलम्बनकरी मातान्नपूर्णेश्वरी ॥१०॥
            </p>
            <p>
              अन्नपूर्णे सदापूर्णे शङ्करप्राणवल्लभे ।
              <br />
              ज्ञानवैराग्यसिद्ध्यर्थं भिक्षां देहि च पार्वति ॥११॥
            </p>
            <p>
              माता च पार्वती देवी पिता देवो महेश्वरः ।
              <br />
              बान्धवाः शिवभक्ताश्च स्वदेशो भुवनत्रयम् ॥१२॥
            </p>
          </div>
        </div>
        <div className="space" />
       {/* <div className="aboutmahantCard">
          <img
            src={aboutMahantImage}
            className="aboutMahantImage"
            alt="Mahant Ji"
          />
          <div className="aboutMahantContent">
            <div className="title">Pandit Vaibhav Arora</div>
            <p className="body">
            Description about the particular man about the particular man Description about the particular man Description about the particular man Description about the particular man Description about the particular man Description about the particular man 
            </p>
          </div>
        </div> */}
        <div className="space" />
      </div>
      <div className="contactLayer minus_index">
        <ContactComponent />
      </div>
    </div>
  );
};

export default AboutPage;

const AboutFlex = () => {
  return (
    <div className="about_flex">
      <h1>
        IN A NUTSHELL - ICE CREAM THAT WILL <br />
        APPEAL EVEN TO THOSE WHO THOUGHT <br />
        THEY WERE COMPLETELY INDIFFERENT TO <br />
        IT.
      </h1>
      <div className="text_right">
        <h2>
          And in general - the business of people with <br />
          absolutely "unfrozen" experience. <br />
          To Mr.Pops.
        </h2>
        <h2>Now we feel like experimenters, actually, as in the beginning.</h2>
        <h2>
          We are like in a laboratory, where the result is tasted. If it suits
          us, it means it will definitely suit one of you!
        </h2>
      </div>
    </div>
  );
};

const AboutMainImageLayers = () => {
  return (
    <div className="mainImageLayersWrapper">
      <img className="mainAboutImage" src={mainAboutPic3} alt="" />
      <div className="backgroundCircle">
        <img className="mainAboutImage" src={mainAboutPic1} alt="" />
      </div>
      <img className="mainAboutImage" src={mainAboutPic2} alt="" />
    </div>
  );
};

//  <div className="backgroundCircle">
//             <div className="about_flex">
//               <h1>
//                 IN A NUTSHELL - ICE CREAM THAT WILL <br />
//                 APPEAL EVEN TO THOSE WHO THOUGHT <br />
//                 THEY WERE COMPLETELY INDIFFERENT TO <br />
//                 IT.
//               </h1>
//               <div className="text_right">
//                 <h2>
//                   And in general - the business of people with <br />
//                   absolutely "unfrozen" experience. <br />
//                   To Mr.Pops.
//                 </h2>
//                 <h2>
//                   Now we feel like experimenters, actually, as in the beginning.
//                 </h2>
//                 <h2>
//                   We are like in a laboratory, where the result is tasted. If it
//                   suits us, it means it will definitely suit one of you!
//                 </h2>
//               </div>
//             </div>
//             <img className="mainAboutImage" src={mainAboutPic1} alt="" />
//           </div>

//           <div className="about_top_layer">
//           <div className="about_flex">
//              <h1>
//                IN A NUTSHELL - ICE CREAM THAT WILL <br />
//                APPEAL EVEN TO THOSE WHO THOUGHT <br />
//                THEY WERE COMPLETELY INDIFFERENT TO <br />
//                IT.
//              </h1>
//              <div className="text_right">
//                <h2>
//                  And in general - the business of people with <br />
//                  absolutely "unfrozen" experience. <br />
//                  To Mr.Pops.
//                </h2>
//                <h2>
//                  Now we feel like experimenters, actually, as in the beginning.
//                </h2>
//                <h2>
//                  We are like in a laboratory, where the result is tasted. If it
//                  suits us, it means it will definitely suit one of you!
//                </h2>
//              </div>

//            </div>
//          AAAAAA
//            <img className="mainAboutImage" src={mainAboutPic2} alt="" />

//           </div>
//           <div className="about_top_layer bottom">
//           <div className="backgroundCircle"/>

//          AAAAAA
//            <img className="mainAboutImage" src={mainAboutPic2} alt="" />

//           </div>
