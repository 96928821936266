import React, { useEffect, useMemo, useRef, useState } from "react";
import "../styles/editProfilePage.scss";
import { useNavigate, useOutletContext } from "react-router";
import { useAuth } from "../appContexts/authContext";
import { AppButton } from "../components/appButton";
import { CircleLoadingAnimation, redprimary, yellowprimary } from "../components/appComponent";
import FormInput from "../components/formInput";
import { UserRequests } from "../requests.js/userRequests";

const EditProfilePage = () => {
  const initialFormRef = useRef({});
  const navigate = useNavigate();
  const formRef = useRef();
  const [isLoading, setisLoading] = useState(false);
  const { isAnonymous } = useAuth();

  const { user } = useOutletContext();


  useEffect(() => {
    if (isAnonymous ) {
      navigate("/");
      return;
    }
  }, [isAnonymous]);

  useEffect(() => {
    if (user === null) {
      navigate("/");
      return;
    }
    return () => {};
  }, []);

  useMemo(() => {
    if (user) {
      initialFormRef.current = {
        name: user.name ?? "",
        phone: user.phone ?? "",
        email: user.email ?? "",
        pan: user.pan ?? "",
        address: user.address ?? "",
        city: user.city ?? "",
        state: user.state ?? "",
        pincode: user.pin_code ?? "",
      };
    }
  }, []);
  const onSaveUserPressed = async () => {
    if (!formRef.current.reportValidity()) {
      return;
    }
    setisLoading(true);
    const res = await UserRequests.updateProfileData({
      name: values.name,
      phone: values.phone,
      email: values.email,
      pan: values.pan,
      address: values.address,
      city: values.city,
      state: values.state,
      pin_code: values.pincode,
    });
    // setisLoading(false);
    navigate("/profile");
  };

  const [values, setValues] = useState({
    name: "",
    phone: "",
    email: "",
    pan: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    ...initialFormRef.current,
  });
  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      // placeholder: "Name",
      errorMessage:
        "Name should be 3-32 characters and shouldn't include any special character!",
      label: "Your Name",
      pattern: "^[A-Za-z0-9 ]{3,32}$",
      required: true,
    },
    {
      id: 2,
      name: "phone",
      type: "phone",
      // placeholder: "Phone",
      errorMessage: "It should be a valid Phone number!",
      label: "Phone",
      pattern: "^[0-9]{10}$",
      required: true,
    },
    {
      id: 3,
      name: "email",
      type: "email",
      // placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      required: true,
    },
    {
      id: 4,
      name: "pan",
      type: "text",
      // placeholder: "Gotra",
      label: "Pan",
      required: false,
      pattern: "^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
      errorMessage: "invalid pan number!",
    },
    {
      id: 5,
      name: "address",
      type: "text",
      // placeholder: "Gotra",
      label: "Your Address",
      required: false,
      pattern: "^[A-Za-z0-9 ,;'@#&!]{3,500}$",
      errorMessage: "Invalid address!",
    },
    {
      id: 6,
      name: "city",
      type: "text",
      // placeholder: "Gotra",
      label: "City",
      required: false,
      pattern: "^[A-Za-z0-9 ,;'@#&!]{1,500}$",
      errorMessage: "Invalid city!",
    },

    {
      id: 7,
      name: "state",
      type: "text",
      // placeholder: "Gotra",
      label: "State",
      required: false,
      pattern: "^[A-Za-z0-9 ,;'@#&!]{1,500}$",
      errorMessage: "Invalid State!",
    },
    {
      id: 8,
      name: "pincode",
      type: "text",
      // placeholder: "Gotra",
      label: "PIN Code",
      required: false,
      pattern: "^[0-9]{6}$",
      errorMessage: "Invalid PIN code!",
    },
  ];
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return(
    <div className={isLoading ? "editProfilePage editProfilePageload" : "editProfilePage"}>
     
      {isLoading && (
        <CircleLoadingAnimation maxwidth={400}>
          <div>Loading...</div>
        </CircleLoadingAnimation>
      )
      }
      {!isLoading && (
        <form ref={formRef} className="editProfileContent">
            <div className='pagetitle pageTittleFont'>Edit Profile</div>

          <div className="formContainer">
            {inputs.slice(0, 3).map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
          </div>
          <div className="formContainer">
            {inputs.slice(3, 4).map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
          </div>
          <div className="formContainer">
            {inputs.slice(4, 8).map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
          </div>
          
          
          <div className="editProfilePageFinalFotter">
          <AppButton
              style={{
                "--btPrimary": redprimary,
                "--btSecondary": yellowprimary,
              }}
              buttonClassName="paymentButtons"
              onClick={() => {
                navigate("/profile");
              }}
            >
              <div className="text">Discard</div>
            </AppButton>
            <AppButton
              style={{
                "--btPrimary": redprimary,
                "--btSecondary": yellowprimary,
              }}
              buttonClassName="paymentButtons"
              onClick={onSaveUserPressed}
            >
              <div className="text">Save</div>
            </AppButton>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditProfilePage;
