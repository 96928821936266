import '../styles/slidebox.scss'
import React, { useState } from "react";


function SlideBox({ children, src, alt, text }) {
    const [showBox, setShowBox] = useState(false);
    const [direction, setDirection] = useState('');
    const [leaveDirection, setleaveDirection] = useState('')
const [boxStyle, setboxStyle] = useState({})
    const handleMouseEnter = (event) => {
        const { clientX, clientY } = event;
        const { left, top, width, height } = event.target.getBoundingClientRect();
        // const centerX = left + width / 2;
        // const centerY = top + height / 2;

        var fromLeft = Math.abs(left - clientX);
        var fromRight = Math.abs(left + width - clientX);
        var fromTop = Math.abs(top - clientY);
        var fromBottom = Math.abs(top + height - clientY);

        // console.log(fromLeft,fromRight,fromTop,fromBottom);
        // console.log("left,top,width,height",left,top,width,height);
        // console.log('clientX,clientY',clientX,clientY)
        if (fromLeft === Math.min(fromLeft, fromRight, fromTop, fromBottom)) {
            setDirection('fromLeft')
            setboxStyle({
                transform: "translateX(200)",
            })

        } else if (fromRight === Math.min(fromRight, fromTop, fromBottom)) {
            setDirection('fromRight')
        }
        else if (fromTop === Math.min(fromTop, fromBottom)) {
            setDirection('fromTop')
        } else {
            setDirection('fromBottom')
        }
        console.log(direction)
        setShowBox(true);
    };
    const handleMouseLeave = (event) => {
        // const { clientX, clientY } = event;
        // const { left, top, width, height } = event.target.getBoundingClientRect();
        // // const centerX = left + width / 2;
        // // const centerY = top + height / 2;

        // var fromLeft = Math.abs(left - clientX);
        // var fromRight = Math.abs(left + width - clientX);
        // var fromTop = Math.abs(top - clientY);
        // var fromBottom = Math.abs(top + height - clientY);

        // // console.log(fromLeft,fromRight,fromTop,fromBottom);
        // // console.log("left,top,width,height",left,top,width,height);
        // // console.log('clientX,clientY',clientX,clientY)
        // if (fromLeft === Math.min(fromLeft, fromRight, fromTop, fromBottom)) {
        //     setleaveDirection('toLeft')
        // } else if (fromRight === Math.min(fromRight, fromTop, fromBottom)) {
        //     setleaveDirection('toRight')
        // }
        // else if (fromTop === Math.min(fromTop, fromBottom)) {
        //     setleaveDirection('toTop')
        // } else {
        //     setleaveDirection('toBottom')
        // }
        // console.log(direction)
        // // setShowBox(true);
        // setShowBox(false);
    };

    return (
        <div className='imageWrapper' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
            {/* <img src={src} alt={alt} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} /> */}
            {children}
            {/* <div className='box' style={{...boxStyle,animationDuration:'2s'}} >
                {text}
            </div> */}
            {/* <div className='box' style={{animation: showBox ? `${direction} 0.3s linear forwards`:` ${leaveDirection} 0.3s linear forwards`}} > */}
                {/* {text} */}
            {/* </div> */}


        </div>
    );
}





























export default SlideBox;
