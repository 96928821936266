import axios from "axios";
import {  ActivitesInterface } from "../interfaces";

export class ActivitesRequests {
       static async getActivites(): Promise<ActivitesInterface[]> {
        try {
            var response = await axios.get(
                `/activites/types`,
            );
            return response.data;
        } catch (error) {
            console.log(error)
            throw error;
        }
    }
}
