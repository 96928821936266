import axios from "axios";
import {   HomePageDataInterface } from "../interfaces";

export class HomePageRequests {
       static async getHomePageData(): Promise<HomePageDataInterface> {
        try {
            var response = await axios.get(
                `/homePageData`,
            );
            return response.data;
        } catch (error) {
            console.log(error)
            throw error;
        }
    }
}
