import React, { useEffect, useMemo, useRef, useState } from "react";
import "../styles/bookPage.scss";
import {
  redprimary,
  yellowprimary,
  AppButton,
  CircleLoadingAnimation,
} from "../components/appComponent";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "../appContexts/authContext";
import { useHeader } from "../components/headerBar";
import FormInput from "../components/formInput";
import { BookingRequests } from "../requests.js/bookingRequests";

const BookPage = () => {
  const [peopleCounter, setpeopleCounter] = useState(1);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { booking } = state ?? {};
  const { isAnonymous, dbUser } = useAuth();
  const [isLoading, setisLoading] = useState(false);
  const formRef = useRef();
  const initialFormRef = useRef({});

  const { setisLoginMenuOpen } = useHeader();

  useEffect(() => {
    
    if ( state===null || booking === null) {
      navigate("/bookings");
      return;
    }
    window.scrollTo(0,0);
    if (isAnonymous) {
      setisLoginMenuOpen(true);
    }

    return () => {};
  }, []);
  function parseDateStringToUTC(dateString) {
    // Split the date string into its year, month, and day components
    const [year, month, day] = dateString.split('-');
  
    // Create a Date object in UTC using the components
    const dateUTC = new Date(Date.UTC(year, month - 1, day));
  
    // Convert the UTC date to an ISO 8601 string
    const dateISOString = dateUTC.toISOString();
  
    return dateISOString;
  }
    useMemo(() => {
    if (dbUser) {
      initialFormRef.current = {
        name: dbUser.name ?? "",
        phone: dbUser.phone ?? "",
        email: dbUser.email ?? "",
        // date: "",
        date: getToday(),
      };
    }
  }, []);

  const onMakePaymentPressed = async () => {
    if (isAnonymous) {
      setisLoginMenuOpen(true);
      return;
    } else {
      console.log("make payment");
    }
    // console.log(formRef.current.reportValidity());
    if (!formRef.current.reportValidity()) {
      return;
    }
    setisLoading(true);
    debugger
    const res = await BookingRequests.getBookingsOrder({
      name: values.name,
      phone: values.phone,
      email: values.email,
      booking_time: parseDateStringToUTC(values.date),
      quantity: peopleCounter,
      booking_type_id: booking.ID,
      user_id: dbUser.userId,
      gotra: values.gotra,
      address: values.address,
      notes: values.notes,
    });
    if (res?.pg_transaction?.payment_url !== undefined) {
      window.location.href = res.pg_transaction.payment_url;
    }
  };
  function getToday() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const calculateAmount = () => {
    return booking?.amount * peopleCounter;
  };
  const convertAmounttoRupees = (amount) => {
    return (amount * 0.01).toFixed(2);
  };
  const [values, setValues] = useState({
    name: "",
    phone: "",
    email: "",
    date: "",
    gotra: "",
    notes: "",
    address: "",
    ...initialFormRef.current,
  });

  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      // placeholder: "Name",
      errorMessage:
        "Name should be 3-32 characters and shouldn't include any special character!",
      label: "Your Name",
      pattern: "^[A-Za-z0-9 ]{3,32}$",
      required: true,
    },
    {
      id: 2,
      name: "phone",
      type: "phone",
      // placeholder: "Phone",
      errorMessage: "It should be a valid Phone number!",
      label: "Phone",
      pattern: "^[0-9]{10}$",
      required: true,
    },
    {
      id: 3,
      name: "email",
      type: "email",
      // placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      required: true,
    },
    {
      id: 4,
      name: "date",
      type: "date",
      // placeholder: "Date",
      label: "Date",
      required: true,
      min: getToday(),
      todayHighlight: true,
      errorMessage: "Invalid date!",
    },
    {
      id: 5,
      name: "address",
      type: "text",
      // placeholder: "Gotra",
      label: "Address",
      required: true,
      pattern: "^[A-Za-z0-9 ,;'@#&!]{3,500}$",
      errorMessage: "Invalid address!",
    },
    {
      id: 6,
      name: "gotra",
      type: "text",
      // placeholder: "Gotra",
      label: "Gotra",
      required: false,
    },
    // {
    //   id: 7,
    //   name: "sthan",
    //   type: "text",
    //   // placeholder: "Gotra",
    //   label: "Sthan",
    //   required: false,
    // },
    {
      id: 7,
      name: "notes",
      type: "text",
      // placeholder: "Gotra",
      label: "Any Wish / Note",
      pattern: "^[A-Za-z0-9 ,;'@#&!]{0,1000}$",
      errorMessage: "Must be less than 1000 valid characters!",
      required: false,
    },
  ];

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className={isLoading ? "bookPage bookPageload" : "bookPage"}>
      {isLoading && (
        <CircleLoadingAnimation maxwidth={400}>
          <div>Loading...</div>
        </CircleLoadingAnimation>
      )}
      {!isLoading && (
        <form ref={formRef} className="bookPageContent">
          <div className="bookPageDetailsContainer">
            <img className="bookPageImage" src={booking?.url} alt="" />
            <div className="bookPageDetailsTextBox">
              <div className="bookPageDetailsTitle">{booking?.name}</div>
              <div className="bookPageDetailsBody">{booking?.description}</div>
            </div>
            <div className="bookPageDetailsPriceBox">
              <div className="bookPageDetailsPriceBoxPrice">
                <div className="rupeesymbol">₹</div> {booking && convertAmounttoRupees(booking.amount)}
              </div>
              <div className="bookPageDetailsPricePerPerson">Per Person</div>
            </div>
          </div>

          <div className="formContainer">
            {inputs.slice(0, 4).map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
            {booking?.type === "pooja" &&
              inputs
                .slice(4, 6)
                .map((input) => (
                  <FormInput
                    key={input.id}
                    {...input}
                    value={values[input.name]}
                    onChange={onChange}
                  />
                ))}
          </div>
          {booking?.type === "pooja" &&
              inputs
                .slice(6,7)
                .map((input) => (
                  <FormInput
                    key={input.id}
                    {...input}
                    value={values[input.name]}
                    onChange={onChange}
                  />
                ))}
          {booking?.type !== "pooja" && (
            <div className="numberOfPeopleCounter">
              <div className="numberOfPeopleCounterText">Number of People</div>
              <div className="counter">
                <div
                  className="counterButton"
                  onClick={() => {
                    if (peopleCounter > 1) {
                      if (peopleCounter >booking.max_count) {
                        setpeopleCounter(booking.max_count);
                      }else{
                        setpeopleCounter(peopleCounter - 1);
                      }
                    }
                  }}
                >-
                </div>
                <div className="counterValue">{peopleCounter}</div>
                <div
                  className="counterButton"
                  onClick={() => {
                    if (peopleCounter >=booking.max_count) {
                      setpeopleCounter(booking.max_count);
                    }else{
                      setpeopleCounter(peopleCounter + 1);
                    }
                  }}
                >
                  +
                </div>
              </div>
            </div>
          )}
          <div className="bookPageFinalFotter">
            {booking?.type !== "pooja" ? (
              <div className="totalContent">
                <div className="totalPrice ">
                  <div className="rupeesymbol">₹</div> {convertAmounttoRupees(calculateAmount())}
                </div>
                <div className="totalText">Total Payable amount</div>
              </div>
            ):<div/>}
            {/* bypass transection - uncomment this to make normal transection*/}
            {/* <AppButton
              style={{
                "--btPrimary": redprimary,
                "--btSecondary": yellowprimary,
              }}
              buttonClassName="paymentButtons"
              onClick={onMakePaymentPressed}
            >
              <div className="text">Make payment</div>
            </AppButton> */}

            {/*bypass transection  */}
            <AppButton
              style={{
                "--btPrimary": redprimary,
                "--btSecondary": yellowprimary,
              }}
              buttonClassName="paymentButtons"
              onClick={async ()=>{
                if (isAnonymous) {
                  setisLoginMenuOpen(true);
                  return;
                } else {
                  console.log("make payment");
                }
                // console.log(formRef.current.reportValidity());
                if (!formRef.current.reportValidity()) {
                  return;
                }
                setisLoading(true);
                debugger
                const res = await BookingRequests.getBookingsOrder({
                  name: values.name,
                  phone: values.phone,
                  email: values.email,
                  booking_time: parseDateStringToUTC(values.date),
                  quantity: peopleCounter,
                  booking_type_id: booking.ID,
                  user_id: dbUser.userId,
                  gotra: values.gotra,
                  address: values.address,
                  notes: values.notes,
                });
                /// await for a second to show loading animation
                setTimeout(() => {
                  setisLoading(false);
                  navigate(`/bookings/${res.ID}`); 
                }, 1000);

              }}
            >
              <div className="text">Book now</div>
            </AppButton>
          </div>
        </form>
      )}
    </div>
  );
};

export default BookPage;
