import React, { useEffect, useMemo, useRef, useState } from "react";
import "../styles/donationPage.scss";
import { AppButton, CircleLoadingAnimation } from "../components/appComponent";
import FormInput from "../components/formInput";
import { useNavigate, useOutletContext } from "react-router";
import { useAuth } from "../appContexts/authContext";
import { useHeader } from "../components/headerBar";
import { DonationgRequests } from "../requests.js/donationRequests";
const redprimary = "#b00e2f";
const yellowprimary = "#fee5ca";
const DonationPage = () => {
  const { isAnonymous, dbUser } = useAuth();
  const { setisLoginMenuOpen } = useHeader();
  const [amountEditable, setAmountEditable] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const { donations } = useOutletContext();
  const [selectedDonationType, setselectedDonationType] = useState(null);
  const initialFormRef = useRef({});
  const formRef = useRef();
  const navigate = useNavigate();

  useMemo(() => {
    if (isAnonymous) {
      setisLoginMenuOpen(true);
    }
    if (dbUser) {
      initialFormRef.current = {
        name: dbUser.name ?? "",
        phone: dbUser.phone ?? "",
        address: dbUser.address ?? "",
        city: dbUser.city ?? "",
        state: dbUser.state ?? "",
        pincode: dbUser.pin_code ?? "",
      };
    }
  }, []);
  useEffect(() => {
    if (donations) {
      setselectedDonationType(donations[0]);
    }
  }, []);

  const [values, setValues] = useState({
    name: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    amount: "",
    pan: "",
    ...initialFormRef.current,
  });

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      // placeholder: "Name",
      errorMessage:
        "Name should be 3-32 characters and shouldn't include any special character!",
      label: "Your Name",
      pattern: "^[A-Za-z0-9 ]{3,32}$",
      required: true,
    },
    {
      id: 2,
      name: "phone",
      type: "phone",
      // placeholder: "Phone",
      errorMessage: "It should be a 10 digit valid Phone number!",
      label: "Phone Number",
      pattern: "^[0-9]{10}$",
      required: true,
    },
    {
      id: 3,
      name: "address",
      type: "address",
      errorMessage: "Invalid address!",
      label: "Address",
      required: true,
    },
    {
      id: 4,
      name: "city",
      type: "city",
      errorMessage: "Invalid city!",
      label: "City",
      required: true,
    },
    {
      id: 5,
      name: "state",
      type: "state",
      errorMessage: "Invalid state!",
      label: "State",
      required: true,
    },
    {
      id: 6,
      name: "pincode",
      type: "pin code",
      errorMessage: "Invalid PIN code!",
      label: "PIN Code",
      pattern: "^[0-9]{6}$",
      required: true,
    },
    {
      id: 7,
      name: "amount",
      type: "amount",
      errorMessage: "Invalid amount!",
      label: "Donation Amount",
      // pattern: "^[0-9]{10,}$",
      pattern: "^[1-9][0-9]{2,}(?<!^99$)$",
      required: true,
      disabled: !amountEditable,
    },
    // {
    //   id: 8,
    //   name: "pan",
    //   type: "pan",
    //   errorMessage: "Invalid pan!",
    //   label: "PAN",
    //   pattern: "^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
    //   required: true,
    // },
  ];
  const onMakePaymentPressed = async () => {
    // !formRef.current.checkValidity()
    // console.log(formRef.current.reportValidity())
    if (isAnonymous) {
      setisLoginMenuOpen(true);
      return;
    } else {
      console.log("make payment");
    }
    if (!formRef.current.reportValidity()) {
      return;
    }
    setisLoading(true);
    const res = await DonationgRequests.getDonationOrder({
      name: values.name,
      phone: values.phone,
      address: values.address,
      city: values.city,
      state: values.state,
      pincode: values.pincode,
      amount: parseInt(values.amount) * 100,
      pan: values.pan,
      donation_type_id: selectedDonationType.ID,
      user_id: dbUser.userId,
    });
    if (res?.pg_transaction?.payment_url !== undefined) {
      window.location.href = res.pg_transaction.payment_url;
    }
  };

  const convertAmounttoRupees = (amount) => {
    return (amount * 0.01).toFixed(0);
  };

  return (
    <div className="donationPage">
      {isLoading && (
        <CircleLoadingAnimation maxwidth={400}>
          <div>Loading...</div>
        </CircleLoadingAnimation>
      )}
      {!isLoading && (
        <div className="bookPageContent">
          <div className="bookPageContentTitle pageTittleFont">Donate</div>
          <form className="bookPageForm" ref={formRef}>
            {inputs.slice(0, 6).map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}

            <div className="dropdownBox">
              <span className="dropdownBoxLabel">Donation Type</span>

              <select
                className="dropdownContent"
                id="don"
                onChange={(e) => {
                  const selectedOption = JSON.parse(e.target.value); // Parse the JSON string to get the object
                  setselectedDonationType(selectedOption);
                  if (selectedOption.amount_editable === true) {
                    setAmountEditable(true);
                    setValues({ ...values, amount: "" });
                  } else {
                    setAmountEditable(false);
                    setValues({
                      ...values,
                      amount: convertAmounttoRupees(selectedOption.amount),
                    });
                  }
                }}
              >
                {donations?.map((donation) => (
                  <option key={donation.ID} value={JSON.stringify(donation)}>
                    {donation.name}
                  </option>
                ))}
              </select>
            </div>
            {inputs.slice(6, 7).map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
            {/* <div className="inputBox">
            <span className="inputBoxLabel">Donation Amount</span>
            <input className="inputBoxInput" type="text" name="amount" id="" />
          </div> */}
            {/* <label class="checkboxContainer">
            <input type="checkbox" />
            <span class="checkmark"></span>
            <span className="label">80G Certificate</span> 
          </label> */}
            {/* <div className="inputBox">
            <span className="inputBoxLabel">PAN</span>
            <input className="inputBoxInput" type="text" name="text" id="" />
          </div> */}
          </form>
          {/* bypass transection - uncomment this to make normal transection*/}
          {/* <AppButton
          style={{
            "--btPrimary": redprimary,
            "--btSecondary": yellowprimary,
          }}
          buttonClassName="paymentButtons"
          onClick={onMakePaymentPressed}
        >
          <div className="text">Make payment</div>
        </AppButton> */}

          {/*bypass transection  */}
          <AppButton
            style={{
              "--btPrimary": redprimary,
              "--btSecondary": yellowprimary,
            }}
            buttonClassName="paymentButtons"
            onClick={async () => {
              if (isAnonymous) {
                setisLoginMenuOpen(true);
                return;
              } else {
                console.log("make payment");
              }
              if (!formRef.current.reportValidity()) {
                return;
              }
              setisLoading(true);
              const res = await DonationgRequests.getDonationOrder({
                name: values.name,
                phone: values.phone,
                address: values.address,
                city: values.city,
                state: values.state,
                pincode: values.pincode,
                amount: parseInt(values.amount) * 100,
                pan: values.pan,
                donation_type_id: selectedDonationType.ID,
                user_id: dbUser.userId,
              });
              /// await for a second to show loading animation
              setTimeout(() => {
                setisLoading(false);
                navigate(`/donations/${res.ID}`);
              }, 1000);
            }}
          >
            <div className="text">Donate now</div>
          </AppButton>
        </div>
      )}
    </div>
  );
};

export default DonationPage;
