import { v4 as uuid } from "uuid";
import { AuthRequests } from "../requests.js/authRequests";
import { UserInterface } from "../interfaces";

export class AppAuth {
  async loginAnonymous(): Promise<[UserInterface, string]> {
    try {
      var credentials: string;
      credentials = uuid();

      const body: LogInBody = {
        credentials: credentials,
      };
      const [user, token] = await AuthRequests.signInUserAnonymous(body);
      return [user, token];
    } catch (error) {
      throw Error("Failed to log in anonymously");
    }
  }

  async loginWithGoogle(token: string): Promise<[UserInterface, string]> {
    return await AuthRequests.signInUserWithGoogele({
      credentials: token,
    });
  }

  async getUser(): Promise<UserInterface> {
    return await AuthRequests.getUser();
  }

  async requestOtpcode(phone: string): Promise<{credentials:string }> {
    return await AuthRequests.requestOtpCode(phone.substring(1), "sdsd");
  }

  async verifyOtpCode(
    credentials: string,
    otp:string
  ): Promise<[UserInterface, string]> {
    return await AuthRequests.verifyOtpCode(credentials, otp);
  }
  async logout(): Promise<void> {
    return await AuthRequests.logout();
  }
}
export type LogInBody = {
  credentials?: string;
  name?: string;
};
