import React, { useState } from "react";
import { ContactComponent } from "./homePage";
import "../styles/common.scss";
import {
  LogInComponent,
  Menuoverlay,
  redprimary,
  AppButton,
} from "../components/appComponent";
const ContactPage = () => {
  var contactStyle = {
    width: "100%",
    backgroundColor: redprimary,
    display: "flex",
    flexDirection: "column",
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "2px",
    top: "0",
    height: "100vh",
    bottom: "0",
  };

  const onContactButtonClick = async (e) => {
    e.preventDefault();
  };
  return (
    <>
      <div className="contactLayer" style={{ ...contactStyle }}>
        <ContactComponent />
      </div>
    </>
  );
};

export default ContactPage;
