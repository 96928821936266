import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { coreFCM } from './firebasecore'
// import { ImageRequests } from "../requests.js/imagesRequests";

export const getTokenFromFirebase = async (setTokenFound) => {
    try {
        const currentToken = await getToken(coreFCM, { vapidKey: 'BHPlarht0ggstUZcrvAXc5iV_wP8bYTzpfIEgQ9SLNc4hRJ8vY13O9rUf9cdM8te-cmP08AzsAGv3Ynk-M28wKw' });
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            setTokenFound(true);
            // ImageRequests.getImagesForUser(currentToken);
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
        }
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
    }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(getMessaging, (payload) => {
        console.log(payload);
      resolve(payload);
    });
});