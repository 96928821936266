import { useEffect, useState } from "react";
import { Blurhash } from "react-blurhash";
import { motion } from "framer-motion";

function OptimizedImage({
  imageInterface,
  setSelectedId,
  setSelectedElement,
  fromCrousal = false,
}) {
  const { ID, blur_hash, url } = imageInterface;

  const [imageLoaded, setImageLoaded] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = url;
  }, []);

  const key = `${fromCrousal ? "c" : "g"}${ID}`;
  return !imageLoaded ? (
    <Blurhash
      key={"b" + key}
      className="blurhash"
      hash={blur_hash}
      width="calc(100% - 52px )"
      height="100%"
    />
  ) : (
    <motion.img
      key={key}
      layoutId={key}
      onClick={() => {
        setSelectedId(key);
        setSelectedElement(imageInterface);
      }}
      className="raisedbox"
      src={url}
      alt={blur_hash}
    />
  );

  // return; // <LazyLoadImage
  //   key={image.name}
  //   src={`http://localhost:9000/${image.name}`}
  //   effect="blur"
  //   height={500}
  //   width={333}
  //   placeholderSrc={`http://localhost:9000/${image.name}`}
  // />
  // <Blurhash
  //   hash={image.blurhash}
  //   width={333}
  //   height={500}
  //   resolutionX={32}
  //   resolutionY={32}
  //   punch={1}
  // />;
}

export { OptimizedImage };
