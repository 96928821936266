import { BrowserRouter, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import './assets/fonts/Cervo-Regular.otf'
import AboutPage from './pages/aboutPage';
import ContactPage from './pages/contactPage';
import GalleryPage from './pages/galleryPage';
import AppAuthComponent from './appContexts/authContext.tsx';
import axios from "axios";
import LoadingPage from './pages/loadingPage';
import { onMessageListener } from './firebaseClasses/appFcm';
import { useEffect } from 'react';
import PrivacyAndPolicyPage from './pages/privacyAndPolicyPage';
import RefundCancellationPolicy from './pages/refundCancellationPolicy';
import HomePage from './pages/homePage';
import BookPage from './pages/bookPage';
import DonationPage from './pages/donationPage';
import { GoogleOAuthProvider } from "@react-oauth/google";
import BookingsListPage from './pages/BookingsListPage';
import ActivityListPage from './pages/activityListPage';
import { HeaderProvider } from './components/headerBar';
import ProfilePage from './pages/profilePage';
import BookingTransactionPage from './pages/bookingTransactionPage';
import DonationTransactionPage from './pages/donationTransactionPage';
import EditProfilePage from './pages/editProfilePage';
import PageNotFound from './pages/pageNotFound';
import DownloadApp from './pages/downloadApp';

function App() {
  axios.defaults.withCredentials = true;
  console.log(process.env)
  if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = "http://localhost:8080/user"
    // axios.defaults.baseURL = "https://serve.shrikashiannapurnamandir.org/user"
  } else {
    axios.defaults.baseURL = "https://serve.shrikashiannapurnamandir.org/user"
  }
  return (

    <GoogleOAuthProvider clientId="694207792759-csu13qiimkrev9buq482p807af8b4659.apps.googleusercontent.com">
      <HeaderProvider>
        <AppAuthComponent>
          <BrowserRouter>
            <AppNavRouters />
          </BrowserRouter>
        </AppAuthComponent>
      </HeaderProvider>
    </GoogleOAuthProvider>

  );
}

const AppNavRouters = () => {
  var navigate = useNavigate();
  useEffect(() => {
    onMessageListener().then(payload => {
      navigate('/gallery');
      console.log('Received message from onMessageListener ', payload);
    }).catch(err => console.log('failed: ', err));
  }, [])

  return (

    (
      < Routes >
        <Route path='/' element={<LoadingPage />} >
          <Route index element={<HomePage />} replace={false}></Route>
          {/* <Route index element={<LoadingPage children={<HomePage />} />} replace={false}></Route> */}
          <Route path='profile' >
            <Route index element={<ProfilePage />} replace={false}></Route>
            <Route path='editProfile' element={<EditProfilePage />} replace={false}></Route>
          </Route>
          <Route path='about' element={<AboutPage />} replace={false}></Route>
          <Route path='contact' element={<ContactPage />} replace={false}></Route>
          <Route path='gallery' element={<GalleryPage />} replace={false}></Route>
          <Route path='bookings'  >
            <Route index element={<BookingsListPage />} replace={false}></Route>
            <Route path='book' element={<BookPage />} replace={false}></Route>
            <Route path=':bookingId' element={<BookingTransactionPage />} replace={false}></Route>
          </Route>
          <Route path='donations'  >
            <Route index element={<DonationPage />} replace={false}></Route>
            <Route path=':donationId' element={<DonationTransactionPage />} replace={false}></Route>
          </Route>
          <Route path='donation' element={<Navigate to='/donations' replace={true} />} replace={false}></Route>
          <Route path='activity' element={<ActivityListPage />} replace={false}></Route>
          <Route path='activites' element={<Navigate to='/activity' replace={true} />} replace={false}></Route>
          {/* <Route path='user/:uid' element={<ProtectedHomeRoute><UserPage/></ProtectedHomeRoute>} replace={false}></Route> */}
          <Route path="*" element={<PageNotFound/>}></Route>
        </Route>
        <Route path='/privacy-and-policy' element={<PrivacyAndPolicyPage />} replace={false}></Route>
        <Route path='/refund-and-cancellation-policy' element={<RefundCancellationPolicy />} replace={false}></Route>
        <Route path='download' element={<DownloadApp/>} replace={false}></Route>
      </Routes >
    )
  )
}

export default App;
