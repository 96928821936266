import React, { useEffect } from 'react'
import { osName } from "react-device-detect";

const DownloadApp = () => {
   useEffect(() => {
    if (osName === 'iOS') {
      window.location.href = 'https://apps.apple.com/in/app/shri-kashi-annapurna-mandir/id6470978694';
    } else if (osName === 'Android') {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.shrikashiannapurnamandir.app';
    } else {
      window.location.href = 'https://pushkal.in';
    }
   }, [])
    
    return (
    <div></div>
  )
}

export default DownloadApp