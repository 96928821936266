import axios from "axios";
import { ImageInterface, ImageResponseInterface } from "../interfaces";

export class ImageRequests {
    static async getImagesForToday(): Promise<ImageInterface[]> {
        try {
            var response = await axios.get(
                `/image/today`,
            );
            return response.data;
        } catch (error) {
            console.log(error)
            throw error;
        }
    }
    static async getAllImages(pageId: number, queryTime?: string): Promise<ImageResponseInterface> {
        try {
            var response = await axios.get(
                `image/all/${pageId}${queryTime === undefined ? "" : `?queryTime=${queryTime}`}`,

            );
            return response.data;
        } catch (error) {
            console.log(error)
            throw error;
        }
    }
    static async getImagesForUser(token: string): Promise<void> {
        try {
            await axios.post(
                `notifications/subscribetogallery`,
               null, {
                    params: {
                        token: token,
                    }
                }
            );
            return;
        } catch (error) {
            console.log(error)
            throw error;
        }
    }
}
