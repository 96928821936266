import "../styles/homePage.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { motion } from "framer-motion";
import maaPic from "../assets/maap.webp";
import useScrollPosition from "../hooks/useScrollPosition";
import main_last_img from "../assets/main_last_img.webp";
import activity_1 from "../assets/activity_1.webp";

import info_layer_1 from "../assets/info_layer_1.webp";
import info_layer_3 from "../assets/info_layer_3.webp";
import carousel_1 from "../assets/carousel_1.webp";
import carousel_2 from "../assets/carousel_2.webp";
import carousel_3 from "../assets/carousel_3.webp";
import carousel_4 from "../assets/carousel_4.webp";
import carousel_5 from "../assets/carousel_5.webp";
import carousel_6 from "../assets/carousel_6.webp";
import carousel_7 from "../assets/carousel_7.webp";
import carousel_8 from "../assets/carousel_8.webp";
import googleDownload from "../assets/google_download.svg";
import appleDownload from "../assets/apple_download.svg";
import download_mobile from "../assets/download_mobile.png";
import { useNavigate, useOutletContext } from "react-router";
import { useInView_opac_slide } from "../hooks/inViewHooks";
import { ReactComponent as IndiaMap } from "../assets/IndiaMap.svg";
import useBreakpoint from "../hooks/breakpoint_hook";
import {
  CircleAnimationSvg,
  CircleLoadingAnimation,
} from "../components/appComponent";

const HomePage = () => {
  var { activites, bookings, total_bookings, total_activites } =
    useOutletContext();

  // header variables

  // home page variables
  var breakpoint = useBreakpoint();
  const islaptop = useMemo(() => breakpoint === "laptop", [breakpoint]);
  var scrollPosition = useScrollPosition();

  useEffect(() => {}, []);

  return (
    <div className="homePage2Wrapper">
      <ShlokeLayer scrollPosition={scrollPosition} />

      <InfoComponent
        islaptop={islaptop}
        scrollPosition={scrollPosition}
        setappBarStyle={{}}
      />
      <ActivityComponent
        islaptop={islaptop}
        activites={activites}
        total_activites={total_activites}
      />
      <BookingComponent
        islaptop={islaptop}
        bookings={bookings}
        total_bookings={total_bookings}
      />
      <MapComponent />
      <ShlokeLayer2 scrollPosition={scrollPosition} />
      <DownloadComponent />
      <ContactComponent />
    </div>
  );
};

export const HomePageTitleComponent = () => {
  return (
    <div className="homeTitleContentWrapper">
      <div>
        <h1>SHRI KASHI</h1>
      </div>
      <div>
        <h1>ANNAPURNA</h1>
      </div>
      <div>
        <h1>MANDIR</h1>
      </div>
    </div>
  );
};

export const ShlokeLayer = ({ scrollPosition }) => {
  const [imgedeStyle, setimgedeStyle] = useState({});

  const layerRef = useRef();

  useEffect(() => {
    var sc = -layerRef.current.getBoundingClientRect().top;

    var parallaxPosition = sc / window.innerHeight;
    // console.log(parallaxPosition);

    if (parallaxPosition <= 1) {
      var fraction = parallaxPosition / 1;

      setimgedeStyle({
        "--width": `max( min(calc(85vh * 0.75) , 85vw) , calc(${
          (1 - fraction) * 100
        }vw) )`,
        "--height": `max(85vh, calc(${(1 - fraction) * 100}vh) )`,
        height: `var(--height)`,
        width: `var(--width)`,
        position: `relative`,
        margin: `${40 * fraction}px ${40 * fraction}px`,
        objectPosition: `center 50% `,
        // transform:` translateY(calc( ${fraction*100}vh  + calc(100vh) - calc(var(--height) / 2 )    ) )`,
      });
    } else if (parallaxPosition <= 2.5) {
      setimgedeStyle({
        width: `min(calc(85vh * 0.75) , 85vw)`,
        height: `85vh`,
        position: `relative`,
        objectPosition: `center 50% `,
      });
    }
  }, [scrollPosition]);
  return (
    <div className="shlokeLayer" ref={layerRef}>
      <ShlokComponent />
      <div className="mainImgWrapper">
        <motion.img
          style={{ ...imgedeStyle }}
          className="maaPic"
          src={maaPic}
          alt=""
        />
      </div>
      <HomePageTitleComponent />
    </div>
  );
};

export const ShlokeLayer2 = ({ scrollPosition }) => {
  const [imgedeStyle, setimgedeStyle] = useState({});

  const layerRef = useRef();

  useEffect(() => {
    var sc = -layerRef.current.getBoundingClientRect().top;

    var parallaxPosition = sc / window.innerHeight;
    if (parallaxPosition <= 0) {
      setimgedeStyle({
        width: "100%",
        height: "100%",
      });
    } else if (parallaxPosition <= 1) {
      var fraction = parallaxPosition / 1;

      setimgedeStyle({
        "--width": `max( min(calc(85vh * 0.75) , 85vw) , calc(${
          (1 - fraction) * 100
        }vw) )`,
        "--height": `max(85vh, calc(${(1 - fraction) * 100}vh) )`,
        height: `var(--height)`,
        width: `var(--width)`,
        position: `relative`,
        margin: `${40 * fraction}px ${40 * fraction}px`,
        objectPosition: `center 50% `,
        // transform:` translateY(calc( ${fraction*100}vh  + calc(100vh) - calc(var(--height) / 2 )    ) )`,
      });
    } else if (parallaxPosition <= 2.5) {
      setimgedeStyle({
        width: `min(calc(85vh * 0.75) , 85vw)`,
        height: `85vh`,
        position: `relative`,
        objectPosition: `center 50% `,
      });
    }
  }, [scrollPosition]);
  return (
    <div className="shlokeLayer" ref={layerRef}>
      <ShlokComponent />
      <div className="mainImgWrapper">
        <motion.img
          style={{ ...imgedeStyle }}
          className="maaPic"
          src={main_last_img}
          alt=""
        />
      </div>
    </div>
  );
};

export const ShlokComponent = () => {
  return (
    <div className="shlokeWrapper">
      <div className="marquee">
        <p>अन्नपूर्णे सदापूर्णे शंकर प्राणवल्लभे ।</p>
        <p>ज्ञान वैराग्य सिध्यर्थं भिक्षां देहिच पार्वति ॥</p>
        <p>माता च पार्वती देवी पिता देवो महेश्वरः ।</p>
        <p>बान्धवाः शिवभक्ताश्च स्वदेशो भुवनत्रयम् ॥</p>
        <p>अन्नपूर्णे सदापूर्णे शंकर प्राणवल्लभे ।</p>
        <p>ज्ञान वैराग्य सिध्यर्थं भिक्षां देहिच पार्वति ॥</p>
        <p>माता च पार्वती देवी पिता देवो महेश्वरः ।</p>
        <p>बान्धवाः शिवभक्ताश्च स्वदेशो भुवनत्रयम् ॥</p>
      </div>
      <div className="marquee marqueereverse">
        <p>अन्नपूर्णे सदापूर्णे शंकर प्राणवल्लभे ।</p>
        <p>ज्ञान वैराग्य सिध्यर्थं भिक्षां देहिच पार्वति ॥</p>
        <p>माता च पार्वती देवी पिता देवो महेश्वरः ।</p>
        <p>बान्धवाः शिवभक्ताश्च स्वदेशो भुवनत्रयम् ॥</p>
        <p>अन्नपूर्णे सदापूर्णे शंकर प्राणवल्लभे ।</p>
        <p>ज्ञान वैराग्य सिध्यर्थं भिक्षां देहिच पार्वति ॥</p>
        <p>माता च पार्वती देवी पिता देवो महेश्वरः ।</p>
        <p>बान्धवाः शिवभक्ताश्च स्वदेशो भुवनत्रयम् ॥</p>
        <p>अन्नपूर्णे सदापूर्णे शंकर प्राणवल्लभे ।</p>
        <p>ज्ञान वैराग्य सिध्यर्थं भिक्षां देहिच पार्वति ॥</p>
        <p>माता च पार्वती देवी पिता देवो महेश्वरः ।</p>
        <p>बान्धवाः शिवभक्ताश्च स्वदेशो भुवनत्रयम् ॥</p>
      </div>
      <div className="marquee">
        <p>अन्नपूर्णे सदापूर्णे शंकर प्राणवल्लभे ।</p>
        <p>ज्ञान वैराग्य सिध्यर्थं भिक्षां देहिच पार्वति ॥</p>
        <p>माता च पार्वती देवी पिता देवो महेश्वरः ।</p>
        <p>बान्धवाः शिवभक्ताश्च स्वदेशो भुवनत्रयम् ॥</p>
        <p>अन्नपूर्णे सदापूर्णे शंकर प्राणवल्लभे ।</p>
        <p>ज्ञान वैराग्य सिध्यर्थं भिक्षां देहिच पार्वति ॥</p>
        <p>माता च पार्वती देवी पिता देवो महेश्वरः ।</p>
        <p>बान्धवाः शिवभक्ताश्च स्वदेशो भुवनत्रयम् ॥</p>
      </div>
    </div>
  );
};

export const InfoComponent = ({ islaptop, scrollPosition, setappBarStyle }) => {
  const layerRef = useRef();
  const [sideImageStyle, setsideImageStyle] = useState({});
  const [mainImageStyle, setmainImageStyle] = useState({});

  var navigate = useNavigate();

  useEffect(() => {
    var sc = -layerRef.current.getBoundingClientRect().top + window.innerHeight;
    var fraction = sc / layerRef.current.clientHeight;

    // if (sc<window.innerHeight ){
    //   setappBarStyle({
    //     "--btPrimary": redprimary,
    //     "--btSecondary": yellowprimary,
    //   });
    // }else{
    //   setappBarStyle({
    //     "--btPrimary": yellowprimary,
    //     "--btSecondary": redprimary,
    //   });
    // }

    if (fraction <= 1 && fraction >= 0) {
      // console.log( " translateY(-" + fraction * 30 + "px)");

      setsideImageStyle({
        transform: " translateY(-" + fraction * 30 + "%)",
      });

      setmainImageStyle({
        transform: `scale(${1.1 + fraction * 0.4})`,
      });
    }
  }, [scrollPosition]);
  const [slideReff, opac_slide_style] = useInView_opac_slide();
  const [slideReff2, opac_slide_style2] = useInView_opac_slide();
  const [slideReff3, opac_slide_style3] = useInView_opac_slide();
  const [slideReff4, opac_slide_style4] = useInView_opac_slide();
  const [slideReff5, opac_slide_style5] = useInView_opac_slide();
  const [slideReff6, opac_slide_style6] = useInView_opac_slide();
  // const [slideReff5,opac_slide_style5]= useInView_opac_slide({pos:'0',fromy:30});
  // console.log("islaptop: " + islaptop);
  return (
    <div className="infoWrapper" ref={layerRef}>
      <motion.div
        className="infoTitle"
        ref={slideReff6}
        style={opac_slide_style6}
      >
        SHRI KASHI ANNAPURNA <br />
        MATH MANDIR
      </motion.div>
      <div className="infoGrid">
        {islaptop && (
          <div className="sideImageWrapper" style={sideImageStyle}>
            <motion.img
              className="sideImage__1"
              src={info_layer_1}
              ref={slideReff4}
              style={opac_slide_style4}
              alt=""
            />
          </div>
        )}
        <div className="mainContent">
          <motion.div
            className="mainImage"
            ref={slideReff}
            style={opac_slide_style}
          >
            <img src={activity_1} style={mainImageStyle} alt="maaPic" />
          </motion.div>
          <motion.h2 ref={slideReff2} style={opac_slide_style2}>
            माता अन्नपूर्णा अन्न की अधिष्ठात्री देवी है
          </motion.h2>
          <motion.p ref={slideReff3} style={opac_slide_style3}>
            संसार में अन्न अर्थात खाद्य की परिपूर्ति करना चींटी से लेकर कुञ्जर
            तक, छोटे से लेकर बड़े जीव तक उसके प्राकृतिक उदर के भरण और शरीर पोषण
            के लिए आवश्यक, उपयुक्त और सुस्वादु भोज्य पदार्थों का उन तक पहुँचाना
            , उन्हें खिलाना और उन्हें खाने के योग्य रखना इन्ही देवी के अनुग्रह
            से होता है। अन्न की अधिष्ठात्री देवी केवल अन्नदाही ही नहीं है, अपितु
            वही परा शक्ति और त्रिभुवन जननी भी है।
          </motion.p>
          &nbsp;
          <motion.p className="infoShloke" style={opac_slide_style3}>
            एषा त्रैलोक्य जननी साऽन्नपूर्णा महेश्वरी,
            <br />
            दुःख-दारिद्र्यशमनी सर्वसंमपत्समृद्धिदा।।
            <br />
            सृष्टि स्थिति - संहार करो योऽसौ महेश्वरः <br />
            स एष भगवान् रुद्रो नृत्यतेऽस्याः पुरः प्रभुः।। <br />
            योगमायां समासाद्य क्रीड़ते यो महेश्वरः
            <br />
            शिव एष ह्ययं शक्तिः मायेयं पुरुषस्त्वसौ।।
            <br />
            यत्किञ्चित् दृश्यते विप्र सर्वमेतत् द्व्यात्मकम्
            <br />
            शिवशक्त्यात्मकं विदधि जगदेतच्चराचरम्।। <br />
          </motion.p>
          &nbsp;
          <motion.p style={opac_slide_style3}>
            सृष्टि की उत्पत्ति, स्थिति और संहृति करने वाली यही आधार शक्ति है।
            इन्होने इन सबके लिए अपने को द्विधा कर लिया। पुरुष रूप में ये ही शिव
            हैं और माया रूप में ये ही शक्ति हैं। इन्ही के आदेश से सृष्टि, स्थिति
            और संहार महेश्वर शिव करते रहते हैं। इनके समक्ष वे नृत्य किया करते
            हैं। जगत में चर और अचर जो कुछ भी दिखाई देता है वह परा शक्ति के
            द्विधाभूत रुपी इसी शिव और शक्ति का प्रसार है। यह महेश्वरी अन्नपूर्णा
            जगज्जननी हैं। दुःख , दरिद्रता का विनाश करती हैं और संपत्ति समृद्धि
            को प्रदान करने वाली हैं।
          </motion.p>
          &nbsp;
          <div
            className="moreWrapper"
            onClick={() => {
              navigate("/about");
            }}
          >
            {/* <div className='AboutUsPic'></div> */}
            <CircleAnimationSvg />
            <div className="readMoreText">Read More</div>
          </div>
        </div>
        {islaptop && (
          <div className="sideImageWrapper" style={sideImageStyle}>
            <motion.img
              className="sideImage__2"
              src={info_layer_3}
              ref={slideReff5}
              style={opac_slide_style5}
              alt=""
            />
            {/* <div className='more' > MORE..</div> */}
          </div>
        )}
      </div>
      <div className="collageWrapper">
        <div
          className="collage"
          onClick={() => {
            navigate("/gallery");
          }}
        >
          &nbsp;
          <img src={carousel_1} alt="1" />
          <img src={carousel_2} alt="2" />
          <img src={carousel_3} alt="3" />
          <img src={carousel_4} alt="4" />
          <img src={carousel_5} alt="5" />
          <img src={carousel_6} alt="6" />
          <img src={carousel_7} alt="7" />
          <img src={carousel_8} alt="8" />
          <img src={carousel_1} alt="1" />
          <img src={carousel_2} alt="2" />
          <img src={carousel_3} alt="3" />
          <img src={carousel_4} alt="4" />
          <img src={carousel_5} alt="5" />
          <img src={carousel_6} alt="6" />
          <img src={carousel_7} alt="7" />
          <img src={carousel_8} alt="8" />
        </div>
      </div>
    </div>
  );
};

export const ActivityComponent = ({
  islaptop = true,
  activites,
  total_activites,
}) => {
  const navigate = useNavigate();
  return (
    <div className="activityWrapper ">
      <div className="title pageTittleFont">ACTIVITES</div>
      <div className="activityGrid">
        {activites?.map((activity, index) => {
          return (
            <div className="item" key={index}>
              <img src={activity.url} alt="" />
              <div className="box">
                <h2>{activity.name}</h2>
                <span>{activity.description}</span>
              </div>
            </div>
          );
        })}
      </div>
      {total_activites > 4 && (
        <CircleLoadingAnimation
          maxwidth={150}
          onClick={() => {
            navigate("/activity");
          }}
        >
          <h4>VIEW MORE</h4>
        </CircleLoadingAnimation>
      )}
    </div>
  );
};

export const BookingComponent = ({
  islaptop = true,
  bookings,
  total_bookings,
}) => {
  var navigate = useNavigate();
  return (
    <div className="bookingWrapper">
      <div className="title pageTittleFont">Bookings</div>
      <div className="bookingGrid">
        {bookings?.map((booking, index) => {
          return (
            <div className="item" key={index}>
              <img src={booking.url} alt="" />
              <div className="box">
                <h2>{booking.name}</h2>
                <span>{booking.description}</span>
                <div
                  className="bookNowContainer"
                  onClick={() => {
                    navigate("/bookings/book", { state: { booking: booking } });
                  }}
                >
                  <CircleAnimationSvg />
                  <div className="bookNowText">Book Now</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {total_bookings > 4 && (
        <CircleLoadingAnimation
          maxwidth={150}
          onClick={() => {
            navigate("/bookings");
          }}
        >
          <h4>VIEW MORE</h4>
        </CircleLoadingAnimation>
      )}
    </div>
  );
};
export const MapComponent = () => {
  const [slideReff, opac_slide_style] = useInView_opac_slide();

  // const styles = useSpring({
  //   scale: isInView ? 1 : 0,

  //   config: {
  //     tension: 300,

  //   },
  // })
  return (
    <div
      className="mapWrapper"
      onClick={() => {
        // navigate('https://goo.gl/maps/VrqBeXqrSNc7gPTp8')
        window.open("https://goo.gl/maps/VrqBeXqrSNc7gPTp8");
      }}
    >
      <div className="content">
        <h3>
          YOU NEED TO TRUST YOUR
          <br />
          OWN EXPERIENCE. VISIT
          <br />
          ONE OF OUR POINTS OF SALE
        </h3>
        <div className="showOnMapWrapper">
          <CircleAnimationSvg />

          <div className="showOnMapText">SHOW ON MAP</div>
        </div>
      </div>
      <motion.div
        className="mapSvgWrapper"
        ref={slideReff}
        style={opac_slide_style}
      >
        <IndiaMap className="mapSvg" />
        {/* <div> */}
        {/* <img src={activity_1} alt='as'/> */}
        {/* </div> */}
      </motion.div>
    </div>
  );
};
export const DownloadComponent = () => {
  return (
    <div className="downloadWrapper">
      <div className="downloadConatiner">
        <div className="dbox dbox1">
          <h3 className="text-white mb-5 capitalize text-4xl lg:text-5xl">
          Download the official app now
          </h3>
          <p className="text-white mb-4">
            With our Android and iOS mobile applications, you can carry your
            spiritual journey in your pocket and access temple services from
            anywhere.
          </p>
          <div className="flex gap-2">
            <a
              href="https://apps.apple.com/in/app/shri-kashi-annapurna-mandir/id6470978694"
              target="_blank"
              rel="noreferrer"
            >
              <img src={appleDownload} alt="Apple Download" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.shrikashiannapurnamandir.app"
              target="_blank"
              rel="noreferrer"
            >
              <img src={googleDownload} alt="Google Download" />
            </a>
          </div>
        </div>
        <div className="dbox dbox2 w-1/2 relative hidden lg:block">
          <img
            className="relative w-full  z-[10]  "
            src={download_mobile}
            alt="Download Mobile"
          />
        </div>
      </div>
    </div>
  );
};

export const ContactComponent = () => {
  return (
    <div className="contactWrapper">
      <h1 className="pageTittleFont">CONTACT</h1>
      <h2>SHRI KASHI ANNAPURNA MATH MANDIR</h2>
      <h2>
        D, 9/1, Vishwanath Gali Varanasi,
        <br />
        Uttar Pradesh - 221001, India
      </h2>
      <div className="footer">
        <a href="/">© Shri Kashi Annapurna Math Mandir / All Rights Reserved</a>
        <a href="/privacy-and-policy">Privacy And Policy</a>
        <div>
          <a
            href="https://www.facebook.com/kashiannapoorna"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
          <a
            href="https://www.instagram.com/anna_purna1996/"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
        </div>
        {/* <a href="">Made By</a> */}
      </div>
    </div>
  );
};

export default HomePage;
