import { useEffect, useState } from "react";
const FormInput = (props) => {
  const [focused, setFocused] = useState(false);
  const { label, errorMessage, onChange, id, ...inputProps } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };
  
  useEffect(() => {
    console.log("value: "+JSON.stringify(props));
  }, [])
  return (
    <div className="inputBox">
      <label className="inputBoxLabel">{label}</label>
      <input
        className="inputBoxInput"
        {...inputProps}
        onChange={onChange}
        onBlur={handleFocus}
        onFocus={() =>
          inputProps.name === "amount" && setFocused(true)
        }
        focused={focused.toString()}
      />
      <div className="errorContainer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clip-path="url(#clip0_2052_6241)">
            <path
              d="M7.99967 1.33301C4.31967 1.33301 1.33301 4.31967 1.33301 7.99967C1.33301 11.6797 4.31967 14.6663 7.99967 14.6663C11.6797 14.6663 14.6663 11.6797 14.6663 7.99967C14.6663 4.31967 11.6797 1.33301 7.99967 1.33301ZM8.66634 11.333H7.33301V9.99967H8.66634V11.333ZM8.66634 8.66634H7.33301V4.66634H8.66634V8.66634Z"
              fill="#F27260"
            />
          </g>
          <defs>
            <clipPath id="clip0_2052_6241">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span className="errorMessage">{errorMessage}</span>
      </div>
    </div>
  );
};

export default FormInput;
