import  { useContext, useState,createContext } from 'react'
import { AppButton, LogInComponent, Menuoverlay, redprimary, yellowprimary } from './appComponent';
import websiteLogo from '../assets/website_logo.png'
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
export const HeaderContext = createContext();

export const useHeader = () => {
  return useContext(HeaderContext);
};

export const HeaderProvider = ({ children }) => {
  const [isLoginMenuOpen, setisLoginMenuOpen] = useState(false);
  const [isMenuOpen, setisMenuOpen] = useState(false);

  return (
    <HeaderContext.Provider value={{isLoginMenuOpen, setisLoginMenuOpen,isMenuOpen, setisMenuOpen}}>
      {children}
    </HeaderContext.Provider>)
}


const   Header = () => {
    // const [isLoginMenuOpen, setisLoginMenuOpen] = useState(false);
    // const [isMenuOpen, setisMenuOpen] = useState(false);
    const {isLoginMenuOpen, setisLoginMenuOpen,isMenuOpen, setisMenuOpen}= useHeader(); 

    const [appBarStyle] = useState({
      "--btPrimary": redprimary,
      "--btSecondary": yellowprimary,
    });
    const navigate=useNavigate();
    var location = useLocation();


    return (
      <>
        <header className="appBar">
          <Link to="/" className="title">
          <img src={websiteLogo} alt="" />
          </Link>
          
          <div className="actions">
            <AppButton
              buttonClassName="menu"
              style={appBarStyle}
              onClick={() => setisMenuOpen(true)}
            >
              <div className="text">MENU</div>
              <div className="lines">
                <div />
                <div />
                <div />
              </div>
            </AppButton>
          </div>
        </header>
        <Menuoverlay
          isMenuOpen={isMenuOpen}
          setisMenuOpen={setisMenuOpen}
          setisLoginMenuOpen={setisLoginMenuOpen}
        />
        {isLoginMenuOpen && (
          <LogInComponent
            isLoginMenuOpen={isLoginMenuOpen}
            setisLoginMenuOpen={setisLoginMenuOpen}
          />
        )}
      </>
    );
  };
  

 
 export default Header