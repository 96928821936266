import React, {  } from "react";
import { useOutletContext } from "react-router";
import "../styles/activityListPage.scss";
const ActivityListPage = () => {
  var { activites } = useOutletContext();

  return (
    <div className="activityPageWrapper">
      <h1 className="title">Activites</h1>
      <div className="activityGrid">
        {activites?.map((booking) => {
          return (
            <div className="item">
              <img src={booking.url} alt="" />
              <div className="box">
                <h2>{booking.name}</h2>
                <span>{booking.description}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ActivityListPage;
