// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from "firebase/auth"
import { getMessaging } from "firebase/messaging";

// import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC82HVZKbMo4sesnnDzAy7snYqx13ntMyU",
  authDomain: "kashiannapurna-62b76.firebaseapp.com",
  projectId: "kashiannapurna-62b76",
  storageBucket: "kashiannapurna-62b76.appspot.com",
  messagingSenderId: "694207792759",
  appId: "1:694207792759:web:588259d218dab0aa9c6872",
  measurementId: "G-PWJHY5QHT9"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
console.log("firebase initiated")
export const analytics = getAnalytics(app);
// export const coreAuth =getAuth(app);
export const coreFCM = getMessaging(app);
// export const coreStorage = getStorage(app);

