// import { useInView  as useInViewSpring } from "@react-spring/web";
// import { buildInteractionObserverThreshold } from "../scripts/thresholds";

import { useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import useBreakpoint from "./breakpoint_hook";

// export function useInView_opc_scale() {
//     const [ref, opc_scale_style] = useInViewSpring(
//         () => ({
//             from: {
//                 opacity: 0,
//                 scale: 0,
//             },
//             to: {
//                 opacity: 1,
//                 scale: 1,
//             },
//         }), {
//             delay:0,

//         rootMargin: '-30% 0% 0% 0% ',
//         amount: buildInteractionObserverThreshold(),
//         once: false,
//     });
//     return [ref, opc_scale_style];
// }

// export function useInView_slide() {
//     const [ref, slide_style] = useInViewSpring(
//         () => ({
//             from: {
//                 y: 100
//             },
//             to: {
//                 y: 0
//             },
//         }), {
//             delay:0,

//             rootMargin: '-30% 0% 0% 0% ',
//             amount: buildInteractionObserverThreshold(),
//             once: false,
//             // threshold: 300,

//     });
//     return [ref, slide_style];
// }

// export const  useInView_opac_slide=({pos='30',fromy=100})=> {
//     const [ref, opac_slide_style] = useInViewSpring(
//         () => ({
//             from: {
//                 opacity: 0,
//                 y: fromy
//             },
//             to: {
//                 opacity: 1,
//                 y: 0
//             },
//         }), {
//             delay:0,
//             rootMargin: `-${pos}% 0% 0% 0%`,
//             amount: buildInteractionObserverThreshold(),
//             once: false,
//             // threshold: 300,

//     });
//     return [ref, opac_slide_style];
// }

export function useInView_opc_scale() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true,amount:"some" });
    const [opc_scale_style, setopc_scale_style] = useState({
        transform: isInView ? "none" : "scale(0)",
        opacity: isInView ? 1 : 0,
        
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1)"
      })
    useEffect(() => {
      setopc_scale_style({
        transform: isInView ? "none" : "scale(1)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1)"
      })
    
    }, [isInView])
 
    return [ref, opc_scale_style];
}


export function useInView_slide() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true,amount:"some" });
    const [slide_style, setslide_style] = useState({
        transform: isInView ? "none" : "translateX(150px)",
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) "
      })
    useEffect(() => {
      setslide_style({
        transform: isInView ? "none" : "translateY(150px)",
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) "
      })
    
    }, [isInView])
    return [ref, slide_style];
}

export const  useInView_opac_slide=()=> {
    const ref = useRef(null);
    var breakpoint = useBreakpoint();
    const isInView = useInView(ref, { once: true,amount:"some"});
    const [opac_slide_style, setOpac_slide_style] = useState({
        transform: isInView ? "none" : "translateY(150px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1)"
      })
    useEffect(() => {
      setOpac_slide_style({
        transform: isInView ? "none" : "translateY(150px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1)"
      })
    
    }, [isInView,breakpoint])
    
    return [ref, opac_slide_style];
}