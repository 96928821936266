import React, { useEffect  } from "react";
import "../styles/bookingsListPageStyle.scss";
import {
  CircleAnimationSvg,
} from "../components/circleAnimationSvg";
import {  useNavigate, useOutletContext } from "react-router";
const BookingsListPage = () => {
var  {bookings} = useOutletContext()
const navigate = useNavigate()
useEffect(() => {
   console.log(bookings)

    return () => {};
  }, []);

  return (
    <div className="bookingsPageWrapper">
      <h1 className="title pageTittleFont">Bookings</h1>
      <div className="bookingGrid">
        {
          bookings?.map((booking) => {
            return (
              <div className="item">
                <img src={booking.url} alt="" />
                <div className="box">
                  <h2>{booking.name}</h2>
                  <span>{booking.description}</span>
                  <div className="bookNowContainer"
                  onClick={
                    () => {
                      navigate('/bookings/book',{state :{booking:booking}})
                    }
                  } 
                  >
                    <CircleAnimationSvg />
                    <div className="bookNowText">Book Now</div>
                  </div>
                </div>
              </div>
            );
          })
        }
        {/* <div className="item">
          <img src={activity_1} alt="" />
          <div className="box">
            <h2>Hariyali Shringar</h2>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quod,
              ipsam cumque dolore recusandae blanditiis, pariatur quae adipisci
              hic dolorum corrupti earum ea quisquam molestias.
            </span>
            <div className="bookNowContainer">
              <CircleAnimationSvg />
              <div className="bookNowText">Book Now</div>
            </div>
          </div>
        </div>
        <div className="item">
          <img src={activity_2} alt="" />
          <div className="box">
            <h2>Annakoot Parv</h2>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quod,
              ipsam cumque dolore recusandae blanditiis, pariatur quae adipisci
              hic dolorum corrupti earum ea quisquam molestias.
            </span>
            <div className="bookNowContainer">
              <CircleAnimationSvg />
              <div className="bookNowText">Book Now</div>
            </div>
          </div>
        </div>
        <div className="item">
          <img src={activity_3} alt="" />
          <div className="box">
            <h2>Dhaan Shringar</h2>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quod,
              ipsam cumque dolore recusandae blanditiis, pariatur quae adipisci
              hic dolorum corrupti earum ea quisquam molestias.
            </span>
            <div className="bookNowContainer">
              <CircleAnimationSvg />
              <div className="bookNowText">Book Now</div>
            </div>
          </div>
        </div>
        <div className="item">
          <img src={activity_4} alt="" />
          <div className="box">
            <h2>17 Divsiya Vrat</h2>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quod,
              ipsam cumque dolore recusandae blanditiis, pariatur quae adipisci
              hic dolorum corrupti earum ea quisquam molestias.
            </span>
            <div className="bookNowContainer">
              <CircleAnimationSvg />
              <div className="bookNowText">Book Now</div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default BookingsListPage;
