import axios from "axios";
import { ProfileDataInterface, UserInterface } from "../interfaces";

export class UserRequests {
  static async getProfileData(): Promise<ProfileDataInterface> {
    try {
      var response = await axios.get(`/currentUser/profile`);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async updateProfileData(data: UserInterface): Promise<UserInterface> {
    try {
      var response = await axios.patch(`/currentUser`, data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async getUser(): Promise<UserInterface> {
    try {
      var response = await axios.get(`/currentUser`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
