import {  useNavigate, useOutletContext } from 'react-router';
import '../styles/bookingTransactionPage.scss'
import pendingImg from "../assets/transection_pending.png";
import  failureImg from "../assets/transection_error.png";
import  successImg from "../assets/transection_success.png";
import  goIcon from "../assets/go_icon.png";
import { useEffect, useState } from 'react';
const BookingTransactionPage = () => {
    const {booking} = useOutletContext()
  const  ispooja=booking?.type==='pooja' 
  const [transaction_time, settransaction_time] = useState()
  const [formattedDate, setformattedDate] = useState("")
  // Parse the input date string
 
  // Format the date in the desired format
 
  useEffect(() => {
    if (booking){
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };
      
      try {
        const formatter = new Intl.DateTimeFormat('en-US', options);
      const tt = new Date(booking?.pg_transaction?.transaction_time);
      settransaction_time(formatter.format(tt))
      
      const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      
      const booking_date = new Date(booking?.booking_time );
      // Get the day, month, and day of the week
      const dayOfWeek = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(booking_date);
      const dayOfMonth = booking_date.getDate();
      const month = monthNames[booking_date.getMonth()];
      
      // Format the date
      const fd = `${dayOfWeek} - ${dayOfMonth} ${month}`;
      setformattedDate(fd)
      } catch (error) {
        setformattedDate("--")
      }
    }
  }, [])
  return (
    <div className='bookingTransactionPage'>
        <div className='pagetitle pageTittleFont'>Booking Details</div>
        <BookingComponent booking={booking} />

        <div className='detailsContainer'>
            <div className='name'>Name</div>
            <div className='value'>{booking?.name}</div>
        </div>
        <div className='detailsContainer'>
            <div className='name'>Phone</div>
            <div className='value'>{booking?.phone}</div>
        </div>
        <div className='detailsContainer'>
            <div className='name'>Email</div>
            <div className='value'>{booking?.email}</div>
        </div>
     
        {ispooja && <div className='detailsContainer'>
            <div className='name'>Address</div>
            <div className='value'>{booking?.address}</div>
        </div>}
        {ispooja && <div className='detailsContainer'>
            <div className='name'>Gotra</div>
            <div className='value'>{booking?.gotra}</div>
        </div>}
        {ispooja && <div className='detailsContainer'>
            <div className='name'>Wish / Notes</div>
            <div className='value'>{booking?.notes}</div>
        </div>}
        <div className='detailsContainer'>
            <div className='name'>Payment Method</div>
            <div className='value'>{booking?.pg_transaction?.transaction_mode}</div>
        </div>
        <div className='detailsContainer'>
            <div className='name'>Time</div>
            <div className='value'>{ transaction_time}</div>
        </div>

        <div className='detailsContainer'>
            <div className='name'>Transaction Id</div>
            <div className='value'>{booking?.pg_transaction?.merchant_transaction_id}</div>
        </div>
        
    </div>
  )
}



  const BookingComponent = ({ booking }) => {
    const [formattedDate, setformattedDate] = useState("--")
    const navigate = useNavigate();
    
   useEffect(() => {
    try {
      const booking_date = new Date(booking?.booking_time);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    // Get the day, month, and day of the week
    const dayOfWeek = new Intl.DateTimeFormat("en-US", {
      weekday: "long",
    }).format(booking_date);
    const dayOfMonth = booking_date.getDate();
    const month = monthNames[booking_date.getMonth()];
  
    // Format the date
    const formattedDate = `${dayOfWeek} - ${dayOfMonth} ${month}`;
    setformattedDate(formattedDate)
    } catch (error) {
      setformattedDate("--")
    }
   
   }, [])
   
  
    const amount = (booking?.pg_transaction?.amount * 0.01).toFixed(2);
    return (
      <div
        key={booking?.pg_transaction?.merchant_transaction_id}
        className="bookingAndDonationContainer"
      >
        <img src={booking?.booking_type?.url} alt="" />
        <div className="textContainer">
          <div className="title">{booking?.booking_type?.name}</div>
         <div className="subtitle" > 
         {/* <div className={`bookingType ${booking.type}`}>{booking.type==="pooja"? "Pooja":"Aarti"}</div> */}
         {/* &nbsp; */}
         <div className="dateTime">{formattedDate}</div> 
         </div>
        </div>
  
        <div
          className="totalContainer"
          onClick={() => {
            navigate(`/bookings/${booking?.ID}`);
          }}
        >
          <img
            src={
              booking?.pg_transaction?.state === "COMPLETED"
                ? successImg
                : booking?.pg_transaction?.state === "PAYMENT_INITIATED"
                ? pendingImg
                : failureImg
            }
            className="statusIcon"
            alt=""
          />
          <div className="amount"><div className="rupeesymbol">₹</div>{amount}</div>
          <img src={goIcon} className="goIcon" alt="" />
        </div>
      </div>
    );
  };
export default BookingTransactionPage