import React from 'react'
import '../styles/privacyAndPolicyStyles.scss'
const PrivacyAndPolicyPage = () => {
    return (
        <div id='privacyPolicyPage'>
            <div id='PrivacyAndPolicyWrapper'>
                <h1>Privacy And Policy</h1>
                <p class="c4">
                    <span class="c3">
                        <h3>1. SCOPE OF THIS PRIVACY POLICY:</h3>
                        Welcome &ndash; Thank you for visiting our site. Your privacy is important to us. To better protect your privacy we provide this notice explaining our online information practices and the choices you can make about the way your information is collected and used on our site.
                        <br /> We, Shree Annapurna Mandir Charitable Trust, as a religious charitable trust shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorisation for any transaction, on account of the Cardholder having exceeded the pre-set limit mutually agreed by us with our acquiring bank from time to time.
                        <br /> Donations or Bookings made once cannot be cancelled, refunded or transferred.
                        <br /> This Privacy Statement discloses the privacy practices of Shree Annapurna Mandir Charitable Trust (&ldquo;Shree Annapurna Mandir&ldquo;, &ldquo;We&rdquo; or &ldquo;Us&rdquo;) and how it treats the information that Shree Annapurna Mandir Charitable Trust, collects and receives. This policy does not apply to the practices of companies that Shree Annapurna Mandir Charitable Trust does not own or control or to people that Shree Annapurna Mandir Charitable Trust, does not employ or manage. The privacy statement covers the following;
                        <br /> The information collected by Shree Annapurna Mandir Charitable Trust. Communications from Shree Annapurna Mandir Charitable Trust. How information collected by Shree Annapurna Mandir Charitable Trust is used. Correction/Updating Personal Information. Opting Out Use of the Shree Annapurna Mandir Charitable Trust&rsquo;s website by a user who is below the age of 18. Linking to third-party websites. Changes to our privacy statement, final statement, and information collected by Shree Annapurna Mandir Charitable Trust:
                        <br /> We will not knowingly use information collected on this website and Mobile Application to others in ways different from what is disclosed in this statement. Shree Annapurna Mandir Charitable Trust collects information from our Devotees at several different points on our website and Mobile Application. For purposes of this Privacy Statement, &ldquo;Personally Identifiable Information&rdquo; refers to information that can directly identify an individual; for example, such information may include an individual&rsquo;s name, mailing address, phone number or email address etc

                        <br /><br /> 1.1 Information that is automatically collected:
                        <br /> We use IP addresses, with the help of a business partner, to analyse trends, administer the website, track Devotees&rsquo; movements, and gather broad demographic information for aggregate use. IP addresses are not linked to Personally Identifiable Information.
                        <br /> <br /> 1.2 Information that is collected by Cookies:
                        <br /> A cookie is a piece of data stored on a Devotee&rsquo;s hard drive containing information about that Devotee. By setting a cookie on our website, the Devotee would not have to log in with an OTP more than once, thereby saving time while on our website. The only drawback to this is that the Devotee will be limited in some areas of our website. Cookies can also enable us to track and target the interests of our Devotees to enhance the experience on our website.
                        <br /> <br /> 1.3 Information that is actively submitted to Shree Annapurna Mandir Charitable Trust:
                        <br /> Registrations and Orders: To donate online or order services from this website, a Devotee must sign up and complete a registration process. During the account creation process, a Devotee must provide the required information (such as a mobile number) so that we can stay in touch with our Devotees and authenticate them. This information is also used to fulfil order and registration requests. To avail of online facilities ordered, a Devotee must provide contact information (such as name, email, address, and PAN details in case of Donations) and financial information (such as a credit card number and its expiration date). This information is used for billing purposes, with the help of our service provider (the company which is providing us with the payment gateway facilities), and to fill Devotees&rsquo; orders. If we have trouble processing an order, this contact information is used to get in touch with the Devotee.
                        <br /> <br /> 1.4 Third-Party Advertisers on Shree Annapurna Mandir Charitable Trust&rsquo;s Website:
                        <br /> Presently, we do not provide any third-party advertisements on our website. No third-party interference is yet allowed and hence there is no question of collecting any information from the third party from our website. In future, if we allow advertisement on this website with authorised permission, we will decide on the collection of private information by a third party or the use of cookies by a third party. The necessary information will be mentioned in this place.
                        <br /> <br /> 1.5 Information Shree Annapurna Mandir Charitable Trust, Obtains from Third Parties:
                        <br /> Presently website is not collecting Devotee information from a third party. Only in the case of Payment Gateway, the company (who so ever provides the payment gateway services for online transaction) collects very critical information like Credit card or debit card number, Expiry date of credit card or debit card, Credit card or debit card holders name, the amount deducted from their credit or debit account and any other relevant information which is must for the online transaction. This information is collected by the payment gateway service provider and this information along with your contact information may be stored with the payment gateway service provider. Shree Annapurna Mandir Charitable Trust stores your contact information and the amounts you donate or pays for various services or products. The payment gateway service provider sends the transaction number to the Shree Annapurna Mandir Charitable Trust website on completion of the payment procedure along with the status of the transaction (like success, failure, cancel or error).
                        <br /> On Successful transaction of payment: In case of a successful transaction of payment for Pooja booking or donation, services or product the Devotee will be informed accordingly via notifications. The details will also inform via email (if the proper email address is provided at the time of payment). The amount will be deducted from Devotee&rsquo;s respective bank and credited to the account of Shree Annapurna Mandir Charitable Trust account. If payment is for an online donation or online Pooja through the temple&rsquo;s official website, the Devotee will get the donation or Pooja booking slip online. The transaction amount is not refundable in any condition or circumstance.
                        <br /> <br /> <h3>2. On Cancel/Failure of transaction of payment:</h3>
                        The devotee can edit/delete facilities from the list of selected facilities at any point before making payment. Once Devotee navigates to the payment gateway for making payment, entry for the same will be stored with us. Devotee enters financial details in the payment gateway and none of these details is stored with us. Only the final confirmation provided by the payment gateway is stored. Payment for the facility will be termed as completed and accepted by Trust only after the realisation of the amount from the bank. Only the facilities for which Trust receives complete payment are subject to cancellation policies. Shree Annapurna Mandir Charitable Trust does not own responsibility for any transactions for which payment is not completed and realised in Trust&#39;s account from the bank.
                        <br /> If Devotee cancels the transaction in the middle of the process, the details are stored in the database and a transaction of the record is generated mentioning that the Devotee has interfered in the middle of a transaction and cancelled the transaction. In case of cancellation of a transaction, the server might have already completed the transaction and Devotee may get the report of the transaction late.
                        <br /> In that case:
                        <br /> If the transaction is for donation cancellation of the order is not possible.
                        <br /> If the transaction is for services or the purchase of a product then Devotee has to enter the cancellation of the order procedure. The devotee must reach out to the support team to check options
                        <br /> <br /> <h3>3. Communications from Shree Annapurna Mandir Charitable Trust:</h3>
                        Welcome Emails and General Correspondence. As of now, we are not sending any welcoming emails but in future, we might send a welcoming email to the email address associated with the account a Devotee has created. This process is designed to prevent e-mail addresses from being used without permission in the creation of the Shree Annapurna Mandir Charitable Trust account. Established Devotees will occasionally receive information on products, services, and daily shringaar darshan. Out of respect for the privacy of our Devotees, we present the option to not receive some of these types of communications. Please see our opt-out options below.
                        <br /> <br /> <h3>4. Site and Service Updates:</h3>
                        We send the Devotee website and service announcement updates. Devotees are not permitted to unsubscribe from service announcements containing important information about the website and/or service. We may communicate with Devotees to provide services that Devotees request or to discuss issues relating to their accounts.
                        {/* <br /> <br /> <br /> 5. Surveys &amp; Contests-From time to time our website requests information from Devotees via surveys or contests. Participation in these surveys or contests is completely voluntary and, as such, the Devotee has a choice whether or not to disclose this information. Information requested may include contact information (such as name and shipping address), and demographic information (such as zip code, and age level). */}
                        <br /> <br /> <h3>5. How Information Collected by Shree Annapurna Mandir Charitable Trust Used:</h3>
                        5.1 Demographic Information-We will share aggregated demographic information with our partners. The aggregated demographic information is not linked to any Personally Identifiable Information that can identify any person.
                        <br /><br /> 5.2 Communicating with Our Devotees-We use Personally Identifiable Information (such as your name, telephone number or email address) to communicate with our Devotees when they have questions, concerns or comments about their accounts or the services that we provide. If you contact us, we may or may not delete your emails or voicemails to us.
                        <br /> <br /> 5.3 Information Shared With Third Parties-We do not share your information with the third party except you provide payment transactions to payment gateway service providers. Use a payment processing company to bill Devotees for goods and services. We will not be responsible or liable for use of the information required by credit card companies in contravention of their policies, contractual obligations or general good business procedures.
                        <br /><br /> 5.4 Addressing Legal Issues-Further, we provide Personally Identifiable Information to respond to subpoenas, court orders, or legal processes, establish or exercise our legal rights or defend against legal claims. Further, we believe it is necessary to share Personally Identifiable Information to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of Shree Annapurna Mandir Charitable Trust&rsquo;s terms of use, or as otherwise required by law.
                        <br /> <br /> <h3>6. Correction/Updating Personal Information:</h3>
                        Devotees must provide accurate and correct contact information (name, postal address, e-mail address, phone number) etc. On providing wrong information if we are unable to process your request, then we are not at all responsible for the act. We provide services and products only if you provide the correct information. Due to wrong information, in that circumstances, the product may not reach your destination in time or sometimes not reach at all. In that case, Shree Annapurna Mandir Charitable Trust is not responsible and has no legal rights to provide the required services or refund your amount whatsoever.
                        <br /> <br /> <h3>7. Opting Out:</h3>
                        Devotees are provided with an opportunity to &ldquo;opt-out&rdquo; of having their information used for purposes not directly related to the services they request. Devotees who no longer wish to receive our newsletter or promotional materials from us may opt out of receiving these communications.
                        <br /> <br /> <h3>8. Use of the Shree Annapurna Mandir Charitable Trust Website by Persons Under Age 18 years:</h3>
                        This website is collecting payment for donations, various Pooja services and products sale. It is not intended for use by anyone under the age of eighteen years (18 years), and any individual under such age should not provide us with Personally Identifiable Information. We do not knowingly contact, market to, solicit, collect or use Personally Identifiable Information from or about any individual under the age of 18. It is possible that by fraud or deception, we may receive information about children under 18. If we are notified that we have personal information regarding a person under the age of 18, as soon as we reasonably verify the information, we will either obtain parental consent or otherwise delete the information from the account and/or service. However, the information may remain on a log in our servers.
                        <br /> <br /> <h3> 9. Linking to Third-Party Websites:</h3>
                        This website contains links to other websites. Please be aware that we are not responsible for the privacy practices of such other websites. We encourage our Devotees to be aware when they leave our website and to read the Privacy Statements of every website that collects Personally Identifiable Information. This Privacy Statement applies solely to information collected by this website.
                        <br /> <br /> <h3>10. General Obligation for using Website:</h3>
                        You shall access our website only for lawful purposes and you shall be responsible for complying with all applicable laws, statutes and regulations in connection with the use of our website. This Website is for your personal and non-commercial use. You shall not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer or sell any information, products or facilities obtained from this Website. You shall not create a hypertext link to the Website or &quot;frame&quot; the Website, except with the express advance written permission of Shree Annapurna Mandir Charitable Trust.
                        <br /> <br /> <h3>11. Changes to Our Privacy Statement:</h3>
                        This Privacy Statement may change, and the use of information that we gather now is subject to the Privacy Statement that is in effect as of the time of use. You are responsible for periodically checking our Privacy Statement. You may opt out of any posted change to our collection, use or disclosure of your personal information. If we make minor changes to this Privacy Statement, the changes will be immediately effective upon posting the revised Privacy Statement to the website. If we make material changes to this Privacy Statement or in how we will use or collect your Personally Identifiable Information, we will prominently post a notification on our website of the changed Privacy Statement ten (10) days before implementing the change. The date at the bottom of this Privacy Statement is the date that the Privacy Statement is effective.
                        <br /> <br /> <h3>12. Termination:</h3>
                        Our Termination Rights: We may at any time at our sole discretion and without giving any reason or any prior notice terminate or temporarily suspend your access to all or any service offered by Shree Annapurna Mandir Charitable Trust.
                        <br /> For The Said Transaction, The Jurisdiction Of The Court Shall Be At Varanasi, Uttar Pradesh State.
                        <br /> <br /> <h3>13. Disclaimer:</h3>
                        Thank you for visiting the Shree Annapurna Mandir Charitable Trust website. By logging in to this website, you agree to the terms and conditions specified on the website. Shree Annapurna Mandir Charitable Trust, India may update the Terms and Conditions at any time without notice. Please review the Terms and Conditions from time to time, as your continued use of the Site means that you accept any changes. You may not modify, license, transfer or sell any information obtained from this website.
                        <br /> Trust has the right to cancel the devotee booking for the Darshan pass or update the charges for online facilities without any prior notice. This website is for informational purposes only and its content is subject to change without notice. If you are dissatisfied with any portion of the site or with any of these terms and conditions, your sole and exclusive remedy is to discontinue using the site. While Shree Annapurna Mandir Charitable Trust follows specific procedures to ensure the accuracy and reliability of its website content, your use of it is at your sole risk. Shree Annapurna Mandir Charitable Trust does not assume any liability deemed to have been caused directly or indirectly by any content or use of a tool on its website. You agree to abide by these Terms and Conditions, and also to comply with all local rules, regulations and laws regarding online conduct and acceptable content.
                        <br /> <br /> <h3>14. Final Statement:</h3>
                        Shree Annapurna Mandir Charitable Trust, Varanasi, Uttar Pradesh will use your information through the Privacy Statement that is currently in effect. If you have any questions about the security at our website, you can send an email to </span>
                    <span class="c0"><a href="mailto:shrikashiannapurnamandir@gmail.com">shrikashiannapurnamandir@gmail.com</a></span>
                    <span class="c3 c6">.</span>
                </p>
                {/* <p class="c1">
                <span class="c5">

                </span>
            </p> */}
                {/* </body> */}
            </div>
        </div>
    );

}
export default PrivacyAndPolicyPage;