import React from 'react'
import '../styles/refundCancellationPolicyStyles.scss'
const RefundCancellationPolicy = () => {
    return (
        <div id='refundCancellationPolicyPage'>
            <div id='refundCancellationPolicyWrapper'>
                <h1>Refund / Cancellation Policy</h1>
                <span>
                    1. Devotees are authorised to deposit their donation and payment of pooja booking via different payment modes such as debit/credit card, net banking, UPI etc whichever is available.
                    <br/><br/>2. Once the donation or booking fee is paid by the devotee, it cannot be refunded via any mode.
                </span>
            </div>
        </div>
    )
}

export default RefundCancellationPolicy