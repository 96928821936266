import axios from "axios";
import { UserInterface } from "../interfaces";
import { LogInBody } from "../classes/appAuth";

export class AuthRequests {
  static async signInUserAnonymous(
    body: LogInBody
  ): Promise<[UserInterface, string]> {
    try {
      var response = await axios.post(`/signin/anonymous`, body, {
        withCredentials: true,
      });
      console.log(response.data);
      return [response.data, response.headers["authorization"]];
    } catch (error) {
      console.log("error in AuthRequests.signInUserAnonymous()");
      console.log(error);
      throw error;
    }
  }
  static async signInUserWithGoogele(body: LogInBody): Promise<[UserInterface ,string]> {
    try {
      var response = await axios.post(`/signin/google`, body, {
        withCredentials: true,
      });
      console.log(response.data);

      return [response.data, response.headers["authorization"]];
    } catch (error) {
      console.log("error in AuthRequests.signInUserWithGoogele()");
      console.log(error);
      throw error;
    }
  }
  static async validateUser(): Promise<Boolean> {
    try {
      var response = await axios.get(`/validate`, {
        withCredentials: true,
      });
      console.log(response.data);
      if (response.status === 200) {
        return true;
      }
      return false;
    } catch (error) {
      console.log("error in AuthRequests.validateUser()");
      console.log(error);
      return false;
    }
  }

  static async requestOtpCode(phone: string,credentials:string): Promise<{credentials:string}> {
    try {
      var response = await axios.post(`/signin/requestOtp`, {
        phone: phone,
        credentials:credentials
      }, {
        withCredentials: true,
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log("error in AuthRequests.requestOtpCode()");
      console.log(error);
      throw error;
    }
  }

  static async verifyOtpCode(
    credentials: string,
    otp: string,
  ): Promise<[UserInterface, string]> {
    try {
      var response = await axios.post(`/signin/verifyOtp`, {
        otp: otp,
        credentials: credentials,
      });
      console.log(response.data);
      return [response.data, response.headers["authorization"]];
    } catch (error) {
      console.log("error in AuthRequests.verifyOtpCode()");
      console.log(error);
      throw error;
    }
  }
  static async logout(
  ): Promise<void> {
    try {
      var response = await axios.post(`/logout`, {});
      console.log(response.data);
      return ;
    } catch (error) {
      console.log("error in AuthRequests.logout()");
      console.log(error);
      throw error;
    }
  }

  static async getUser(): Promise<UserInterface> {
    try {
        var response = await axios.get(
            `/currentUser`,{
                withCredentials:true,

            }
        );
        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

  // static async refreshToken(body:LogInBody):Promise<UserInterface> {
  //     try {
  //         var response = await axios.post(
  //             `/signin/anonymous`, body,{
  //                 withCredentials:true,
  //             }
  //         );
  //         console.log(response.data);

  //         return response.data;
  //     } catch (error) {
  //         console.log("error in AuthRequests.signInUserAnonymous()");
  //         console.log(error)
  //         throw error;
  //     }
  // }

  // static async logInUser(user: UserInterface): Promise<UserInterface> {
  //     try {
  //         var response = await axios.post(
  //             `/user/signin`, user,{
  //                 withCredentials:true,
  //             }
  //         );
  //         console.log(response.data);

  //         return response.data;
  //     } catch (error) {
  //         console.log("error in AuthRequests.logInUser()");

  //         console.log(error)
  //         throw error;
  //     }
  // }
  
  // static async patchUser(user: UserInterface): Promise<UserInterface> {
  //     try {
  //         var response = await axios.patch(
  //             `/user`, user,
  //         );
  //         return response.data;
  //     } catch (error) {
  //         console.log(error)
  //         throw error;
  //     }
  // }
  // static async ping(): Promise<void> {
  //     try {
  //         var response = await axios.get(
  //             `/ping`,{
  //                 withCredentials:true
  //             }
  //         );
  //         return response.data;
  //     } catch (error) {
  //         console.log("error in logInUser.logInUser");

  //         console.log(error)
  //         throw error;
  //     }
  // }
}
