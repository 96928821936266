import { redprimary, yellowprimary } from "./appComponent";


export function AppButton({
  children,
  buttonClassName,
  onClick,
  style = {
    "--btPrimary": redprimary,
    "--btSecondary": yellowprimary,
  },
}) {
  return (
    // onPressed={()=>{console.log("sssss")}}
    <button className="buttonWrapper" style={style} onClick={onClick}>
      <div className={`button ${buttonClassName}`}>
        {children}
        <div className="box" />
      </div>
    </button>
  );
}