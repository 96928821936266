
import React from 'react'
import  '../styles/pageNotFound.scss'
import _404Img from "../assets/404.png";
import { useNavigate } from 'react-router';
import { CircleAnimationSvg } from '../components/circleAnimationSvg';

const PageNotFound = () => {
    const navigate = useNavigate();

  return (
    <div className='pageNotFoundWrapper'> 
        <img src={_404Img}  alt='404.png' id='_404'/>
        <div className="backToHomeWrapper" onClick={()=>{
          navigate("/")
        }}>
          <CircleAnimationSvg />
          <div className="backToHomeText">BACK TO HOME</div>
        </div>
    </div>
  )
}

export default PageNotFound