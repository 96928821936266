import { ReactComponent as CircleSvg } from "../assets/moreButtonAnimation.svg";
export const CircleAnimationSvg = ({ children }) => {
  return (
    <div className="circleAnimationSvgWrapper">
      <CircleSvg className="circleSvg" />
      <div className="hoverLayer" />
      <CircleSvg className="circleSvg arrow" />
    </div>
    // <div>CircleAnimationSvg</div>
  );
};
export const CircleLoadingAnimation = ({children,onClick,maxwidth = 200}) => {
  return (
    <div
      className={`circleLoadingAnimation ${onClick ? "hoverAnimation" : ""}`}
      onClick={onClick}
      style={{ "--maxwidth": `${maxwidth}px` }}
    >
      <CircleSvg className="circleSvg" />
     {onClick &&  <span className="hoverLayer" />}
      <div>{children}</div>
    </div>
    // <div>CircleAnimationSvg</div>
  );
};
