import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import pendingImg from "../assets/transection_pending.png";
import failureImg from "../assets/transection_error.png";
import successImg from "../assets/transection_success.png";
import "../styles/donationTransactionPage.scss";
import goIcon from "../assets/go_icon.png";
const DonationTransactionPage = () => {
  const { donation } = useOutletContext();
  const [transaction_time, settransaction_time] = useState("");
  useEffect(() => {
    if (donation) {
      const tt = new Date(donation?.pg_transaction?.transaction_time);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const formatter = new Intl.DateTimeFormat("en-US", options);
      settransaction_time(formatter.format(tt));
    }
  }, []);
  return (
    <div className="donationTransactionPage">
      <div className="pagetitle pageTittleFont">Donation Details</div>
      <DonationComponent  donation={donation}/>

      <div className="detailsContainer">
        <div className="name">Name</div>
        <div className="value">{donation?.name}</div>
      </div>
      <div className="detailsContainer">
        <div className="name">Phone</div>
        <div className="value">{donation?.phone}</div>
      </div>
      {/* <div className='detailsContainer'>
            <div className='name'>Email</div>
            <div className='value'>{donation?.email}</div>
        </div> */}
      <div className="detailsContainer">
        <div className="name">Address</div>
        <div className="value">{donation?.address + ", "}</div>
      </div>
      <div className="detailsContainer">
        <div className="name">Payment Method</div>
        <div className="value">{donation?.pg_transaction?.transaction_mode}</div>
      </div>
      <div className="detailsContainer">
        <div className="name">Time</div>
        <div className="value">{transaction_time}</div>
      </div>
      <div className="detailsContainer">
        <div className="name">Transaction Id</div>
        <div className="value">
          {donation?.pg_transaction?.merchant_transaction_id}
        </div>
      </div>
    </div>
  );
};

const DonationComponent = ({ donation }) => {
  const navigate = useNavigate();

  const amount = (donation?.pg_transaction?.amount * 0.01).toFixed(2);
  return (
    <div
      key={donation?.pg_transaction?.merchant_transaction_id}
      className="bookingAndDonationContainer"
    >
      <div />
      <div className="textContainer">
        <div className="title">{donation?.donation_type?.name}</div>
        {/* <div className="dateTime">{formattedDate}</div> */}
        <div className="dateTime">
          Status:-{" "}
          {donation?.pg_transaction?.state === "COMPLETED"
            ? "Success"
            : donation?.pg_transaction?.state === "PAYMENT_INITIATED"
            ? "Pending"
            : "Failed"}
        </div>
      </div>

      <div
        className="totalContainer"
        onClick={() => {
          navigate(`/donations/${donation?.ID}`);
        }}
      >
        <img
          src={
            donation?.pg_transaction?.state === "COMPLETED"
              ? successImg
              : donation?.pg_transaction?.state === "PAYMENT_INITIATED"
              ? pendingImg
              : failureImg
          }
          className="statusIcon"
          alt=""
        />
        <div className="amount"><div className="rupeesymbol">₹</div>{amount}</div>
        <img src={goIcon} className="goIcon" alt="" />
      </div>
    </div>
  );
};

export default DonationTransactionPage;
