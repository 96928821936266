import axios from "axios";
import {  DonationInterface, DonationTypeInterface } from "../interfaces";

export class DonationgRequests {
    // static async postDonation(donationInterface: DonationInterface): Promise<DonationInterface> {
    //     try {
    //         var response = await axios.post(
    //             `/user/donation`, donationInterface,
    //         );
    //         return response.data;
    //     } catch (error) {
    //         console.log(error)
    //         throw error;
    //     }
    // }
    static async getDonations(): Promise<DonationInterface[]> {
        try {
            var response = await axios.get(
                `/donation`,
            );
            return response.data;
        } catch (error) {
            console.log(error)
            throw error;
        }
    }
    static async getDonationById(id : number): Promise<DonationInterface[]> {
        try {
            var response = await axios.get(
                `/donation/order/${id}?hardReload=true`,
            );
            return response.data;
        } catch (error) {
            console.log(error)
            throw error;
        }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
    }                                                                                                                   
    static async getDonationTypes(): Promise<DonationTypeInterface[]> {
        try {
            var response = await axios.get(                                                                                                                                                     
                `/donation/types`,
            );
            return response.data;
        } catch (error) {
            console.log(error)
            throw error;
        }
    }
    static async getDonationOrder(body:DonationInterface): Promise<DonationInterface> {
        try {
            var response = await axios.post(
                `/donation/order`,
                body

            );
            return response.data;
        } catch (error) {
            console.log(error)
            throw error;
        }
    }
}
