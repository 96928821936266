import {useState, useEffect} from 'react';

const getDeviceConfig = (width) => {
  if(width < 453) {
    return 'mobile';
  } else if(width >= 435 && width < 900 ) {
    return 'tablet';
  } else if(width >= 900 ) {
    return 'laptop';
  } 
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth));
  
  useEffect(() => {
    const calcInnerWidth = function() {
      setBrkPnt(getDeviceConfig(window.innerWidth))
    }; 
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
}
export default useBreakpoint;