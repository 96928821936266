import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../appContexts/authContext";
import { redprimary, yellowprimary, AppButton } from "./appComponent";

export const Menuoverlay = ({
  isMenuOpen,
  setisMenuOpen,
  setisLoginMenuOpen,
}) => {
  const [menuoverlayOpacity, setmenuoverlayOpacity] = useState(0);
  const [menuoverlayStyle, setmenuoverlayStyle] = useState({ display: "none" });
  const [menuCircleStyle, setmenuCircleStyle] = useState({ width: 0 });
  const menuOverlayReff = useRef();
  var navigate = useNavigate();
  const onMenuClose = async () => {
    setbackAnimation();
    await new Promise((res) => setTimeout(res, 100));
    setmenuCircleStyle({ height: "0" });
    await new Promise((res) => setTimeout(res, 200));
    setisMenuOpen(false);
    setmenuoverlayOpacity(0);
    await new Promise((res) => setTimeout(res, 300));
    setmenuoverlayStyle({ display: "none" });
  };
  const onMenuButtonPressed = async () => {
    setduration();
    setmenuoverlayStyle({ display: "block" });
    await new Promise((res) => setTimeout(res, 100));
    setmenuoverlayOpacity(1);
    setmenuCircleStyle({ height: "200%" });
  };
  useEffect(() => {
    if (isMenuOpen === true) {
      onMenuButtonPressed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  const setduration = () => {
    var span = menuOverlayReff.current.getElementsByTagName("li");
    for (let j = 0; j < span.length; j++) {
      span[
        j
      ].firstChild.style.animation = `text-up-animation 1s cubic-bezier(0.175, 0.885, 0.32, 1.175) forwards`;
      span[j].firstChild.style.animationDelay = `${0.2 + 0.05 * j}s`;
    }
  };

  const setbackAnimation = () => {
    var span = menuOverlayReff.current.getElementsByTagName("li");
    for (let j = 0; j < span.length; j++) {
      span[j].firstChild.style.animation = `text-down-animation 0.5s forwards`;
    }
  };

  const onLoginButtonTap = async (e) => {
    e.preventDefault();
    console.log("logInPressed");
    await onMenuClose();
    setisLoginMenuOpen(true);
  };

  const onAboutButtonClick = async (e) => {
    e.preventDefault();
    await onMenuClose();
    await new Promise((res) => setTimeout(res, 300));
    navigate("/about");
  };
  const onProfileButtonClick = async (e) => {
    e.preventDefault();
    await onMenuClose();
    await new Promise((res) => setTimeout(res, 300));
    navigate("/profile");
  };

  const onHomeButtonClick = async (e) => {
    e.preventDefault();
    await onMenuClose();
    await new Promise((res) => setTimeout(res, 300));
    navigate("/");
  };

  const onContactButtonClick = async (e) => {
    e.preventDefault();
    await onMenuClose();
    await new Promise((res) => setTimeout(res, 300));
    navigate("/contact");
  };
  const onGalleryButtonClick = async (e) => {
    e.preventDefault();
    await onMenuClose();
    await new Promise((res) => setTimeout(res, 300));
    navigate("/gallery");
  };
  const onBookingsButtonClick = async (e) => {
    e.preventDefault();
    await onMenuClose();
    await new Promise((res) => setTimeout(res, 300));
    navigate("/bookings");
  };
  const onDonationsButtonClick = async (e) => {
    e.preventDefault();
    await onMenuClose();
    await new Promise((res) => setTimeout(res, 300));
    navigate("/donations");
  };
  const onLogOutButtonPressed = async (e) => {
    e.preventDefault();
    await onMenuClose();
    logout();
  };
  var { authUiState, logout, isAnonymous } = useAuth();
 

  useEffect(() => {
    setduration();
  }, []);
  return (
    <div
      className="overlay"
      ref={menuOverlayReff}
      style={{ ...menuoverlayStyle }}
    >
      <div className="overlayBackGround" onClick={onMenuClose}></div>
      <div className="overLayMenu">
        <div className="circle" style={menuCircleStyle} />
        {/* <button>aaaaaaaaaaaaaAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA</button> */}
        <AppButton
          buttonClassName="menu"
          style={{
            "--btPrimary": redprimary,
            "--btSecondary": yellowprimary,
          }}
          onClick={onMenuClose}
        >
          <div className="text">MENU</div>
          <div className="cross">X</div>
        </AppButton>
        <ul id="menuItems" className="menuUl">
          <li onClick={onHomeButtonClick}>
            <div>Home</div>
          </li>
          <li onClick={onAboutButtonClick}>
            <div>About</div>
          </li>
          {!isAnonymous && (
            <li onClick={onProfileButtonClick}>
              <div>Profile</div>
            </li>
          )}
          <li onClick={onGalleryButtonClick}>
            <div>Gallery</div>
          </li>
          {/* <li><div>Activities</div></li> */}
          <li onClick={onBookingsButtonClick}>
            <div>Bookings</div>
          </li>
          <li onClick={onDonationsButtonClick}>
            <div>Donation</div>
          </li>
          <li onClick={onContactButtonClick}>
            <div>Contacts</div>
          </li>
          {/* <li onClick={onLoginButtonTap}><div>Log In</div></li> */}
          {
            {
              // 'signedOut':  <li onClick={onLoginButtonTap}><div>Log In</div></li>,
              signedOut: <></>,
              loading: <></>,
              otpSent: (
                <li onClick={onLoginButtonTap}>
                  <div>Log In</div>
                </li>
              ),
              signedIn: isAnonymous ? (
                <li onClick={onLoginButtonTap}>
                  <div>Log In</div>
                </li>
              ) : (
                <li onClick={onLogOutButtonPressed}>
                  <div>Log Out</div>
                </li>
              ),
            }[authUiState]
          }
        </ul>
      </div>
    </div>
  );
};
